import api from "./api";
import { URL_PROCESS_BASE_PATH } from "../utils/Constants";

export class ProcessService {
  getSolicitations = async () => {
    const url = `${URL_PROCESS_BASE_PATH}/Solicitation/starteds`;
    try {
      // const mData = localStorage.getItem("asd");
      // if (mData != null && mData !== undefined && mData !== "null")
      //   return JSON.parse(mData);
      const { data } = await api.get(url);
      // localStorage.setItem("asd", JSON.stringify(data));
      return data;
    } catch (err) {
      return null;
    }
  };

  getSolicitationbyId = async (solicitationId) => {
    const url = `${URL_PROCESS_BASE_PATH}/Solicitation/${solicitationId}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  updateStatusSolicitation = async (solicitationUpdate) => {
    const url = `${URL_PROCESS_BASE_PATH}/Solicitation`;
    try {
      const { data } = await api.put(url, solicitationUpdate);
      return data;
    } catch (err) {
      return null;
    }
  };

}

const processService = new ProcessService();
export default processService;
