import React, { useState } from "react";
import { Button, FormGroup, Row, Col, ListGroupItem, ListGroup, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { Input, TextInputMask } from "../../../atoms/Input";
import { Select } from "../../../atoms/Select";
import LoadingComponent from "../../../atoms/LoadingComponent/LoadingComponent"

import ArrowBack from "../../../../assets/icons/arrowBack.png";
import { BackButton } from "../../../theme/Global";

const GetDefault = (activationCodeList, personId) => {
    const newList = [];
    for (let i = 0; i < activationCodeList.length; i++) {

        if (activationCodeList[i].personId === personId || personId === 0) {
            const element = activationCodeList[i];
            newList.push(element)
        }
    }
    return newList;
};

const Step2Info = ({
    activationCodeList,
    activationCodePersonList,
    onRegister,
    onChange,
    isValidDocument,
    defalutDocument,
    defalutName,
    isDefalutName,
    onBackSlide,
    onBack,
    isLoading
}) => {
    if (isLoading)
        return <LoadingComponent />
    const hasActivationCodeList = activationCodeList != null && activationCodeList !== undefined &&
        activationCodeList.length > 0;
    const [name, setName] = useState(defalutName);
    if (name !== defalutName)
        setName(defalutName)
    const [ownerDocument, setDocument] = useState("");

    // if (document !== defalutDocument)
    //     setDocument(defalutDocument)
    const [cellphone, setCellphone] = useState("");

    const [activationSelected, setActivationSelected] = useState(
        hasActivationCodeList ? JSON.stringify(activationCodeList[0]) : []);
    const list = [];
    // try {
    //     if (hasActivationCodeList)
    //         if (activationCodeList[0].services !== undefined)
    //             activationCodeList[0].services.map((item) => {
    //                 list.push(JSON.stringify(item))
    //                 return item;
    //             })
    // } catch (_) { }
    const [servicesSelected, setServicesSelected] = useState([]);

    const [activationCodeListAux, setActivationCodeListAux] = useState(GetDefault(activationCodeList, activationCodePersonList[0].personId));
    return <>
        {/* <Row>
            <Col style={{ "display": "flex", "flex-direction": "row" }}>
                <TransparentButton style={{ "marginRight": "2%" }} onClick={onBackSlide}><img alt="voltar" src={arrowBack} style={{ "height": "25px", "width": "25px" }} /></TransparentButton>
                <h1>Nova Conta</h1>
            </Col>
        </Row> */}
        <BackButton
            // color="link"
            onClick={onBackSlide}
        >
            <img src={ArrowBack} alt="voltar" />
            Voltar
        </BackButton>
        <FormGroup>
            <label>Nome</label>
            <Input
                name="name"
                placeholder="Nome"
                value={name}
                disabled={isDefalutName}
                onChange={(event) => {
                    setName(event.target.value)
                    onChange(event)
                }}
            />
        </FormGroup>

        <FormGroup>
            <label>Celular</label>
            <TextInputMask
                name="cellphone"
                mask={["(99) 9999-9999", "(99) 9 9999-9999"]}
                placeholder="(00) 0000-0000"
                onChange={(event) => {
                    setCellphone(event.target.value)
                    onChange(event);
                }}
                value={cellphone}
            />
        </FormGroup>

        <FormGroup>
            <label>E-mail</label>
            <div style={{ marginTop: "10px" }}>
                <Input
                    name="email"
                    type="email"
                    placeholder="exemplo@email.com"
                    onChange={onChange}
                />
            </div>
        </FormGroup>

        {
            hasActivationCodeList ? (
                <>

                    <FormGroup>
                        <label>Selecione o dono do código de ativação</label>
                        <Select onChange={(event) => {
                            const person = JSON.parse(event.target.value);
                            const myList = GetDefault(activationCodeList, person.personId);
                            setActivationCodeListAux(myList);
                            setActivationSelected(JSON.stringify(myList[0]));
                            setServicesSelected([]);
                        }}>
                            {
                                activationCodePersonList.map((item) => {
                                    const str = JSON.stringify(item);
                                    return <option aria-label="Save" selected={activationSelected === str} value={str}>{`${item.personName}`}</option>
                                })
                            }
                        </Select>
                    </FormGroup>
                </>
            ) : (

                <Alert color="danger">
                    Nâo há códigos de ativação disponíveis.
                </Alert>
            )
        }

        {
            hasActivationCodeList ? (
                <>

                    <FormGroup>
                        <label>Código de ativação</label>
                        <Select onChange={(event) => {
                            setActivationSelected(event.target.value)
                            // const obj = JSON.parse(event.target.value)
                            // const myList = [];
                            // obj.services.map((item) => {
                            //     myList.push(JSON.stringify(item))
                            //     return item;
                            // })
                            setServicesSelected([]);
                        }}>
                            {
                                activationCodeListAux.map((item) => {
                                    const str = JSON.stringify(item);
                                    return <option aria-label="Save" selected={activationSelected === str} value={str}>{`${item.code} - ${item.description}`}</option>
                                })
                            }
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <ServicesList activationSelected={JSON.parse(activationSelected)} servicesSelected={servicesSelected} onChange={(checked, value) => {
                            if (checked) {
                                const newList = JSON.parse(JSON.stringify(servicesSelected));
                                newList.push(value)
                                setServicesSelected(newList)
                            } else {
                                const newList = [];
                                for (let i = 0; i < servicesSelected.length; i++) {
                                    const element = servicesSelected[i];
                                    if (element !== value) {
                                        newList.push(element)
                                    }
                                }
                                setServicesSelected(newList)
                            }
                        }} />
                    </FormGroup>

                    <Register
                        servicesSelected={servicesSelected}
                        onPress={() => onRegister(servicesSelected, JSON.parse(activationSelected),)}
                    />
                </>
            ) : (

                <Alert color="danger">
                    Nâo há códigos de ativação disponíveis.
                </Alert>
            )
        }
    </>
};

const Register = ({ onPress, servicesSelected }) => {

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <br />
            <Button
                className="btn-fill"
                color="primary"
                onClick={() => {
                    try {
                        if (servicesSelected.length <= 0)
                            toggle();
                        else
                            onPress();
                    } catch (_) { }
                }}>
                Registrar
            </Button>
            <Modal isOpen={modal} toggle={toggle} className="dark-modal" backdrop="static">
                <ModalHeader toggle={toggle}>Atenção</ModalHeader>
                <ModalBody>
                    <p>
                        Essa pessoa será cadastrada, porém não possuirá serviços vinculados a ela. Deseja continuar mesmo assim?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        toggle();
                        onPress();
                    }}>Sim</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}


const ServicesList = ({ activationSelected, servicesSelected, onChange }) => <>
    <ListGroup>
        {
            activationSelected.services && activationSelected.services.map((item) => {
                const str = JSON.stringify(item);
                return <ListGroupItem >
                    <input
                        type="checkbox"
                        id={str}
                        checked={servicesSelected.includes(str)}
                        value={str}
                        onChange={(event) => {
                            onChange(event.target.checked, str);
                        }}
                        style={{
                            "transform": "scale(1.5)",
                            "marginRight": "15px"
                        }}
                    />
                    <label htmlFor={str}>{item.description}</label>
                </ListGroupItem>
            })
        }
    </ListGroup>
</>

export default Step2Info;