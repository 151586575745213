
import { inject, observer } from 'mobx-react';
import React from 'react';
import SideMenuOption from './SideMenuOption';
import { Board, Content, OverContent, SideMenu, SideMenuList } from './styles';

import TopNavMenu from './TopMenu';

@inject("UserStore", "UIStore")
@observer
class Dasboard extends React.Component {

    constructor(props) {
        super(props);
        const { UIStore, UserStore } = props;
        try {
            if (
                UIStore.currentMarkdown == null ||
                UIStore.currentMarkdown === undefined ||
                UIStore.currentMarkdown === ""
            ) {
                UIStore.setCurrentMarkdown(UserStore.menus.home.content);
                
            }
        } catch (e) { }
    }

    render() {
        const { UserStore, UIStore, history, onMouseEnter, isMarkdown } = this.props;
        return (<Board sideMenuOpen={UIStore.leftNavBarIsOpen} topMenuOpen={UIStore.topNavBarIsOpen} onMouseEnter={onMouseEnter}>
            <TopNavMenu history={history} />
            <SideMenu
                sideMenuOpen={UIStore.leftNavBarIsOpen}
                topMenuOpen={UIStore.topNavBarIsOpen}
            >
                <SideMenuList>
                    {
                        (UserStore.menus != null &&
                            UserStore.menus !== undefined ?
                            UserStore.menus.verticalMenus :
                            [])
                            .map((item) => <SideMenuOption isLateral item={item} history={history} />)
                    }
                </SideMenuList>
            </SideMenu>
            <div>
                <OverContent sideMenuOpen={UIStore.leftNavBarIsOpen} >
                    <div className="name">
                        <div className="con">
                            {
                                UserStore.name
                            }
                        </div>
                    </div>
                </OverContent>
                <Content isMarkdown={isMarkdown} sideMenuOpen={UIStore.leftNavBarIsOpen} topMenuOpen={UIStore.topNavBarIsOpen}>
                    {
                        this.props.children
                    }
                </Content>
            </div>

        </Board>);
    }
}
export default Dasboard;