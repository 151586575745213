import React, { useState } from "react";
import { FormGroup, Button, Row, Col, Card, CardBody, CardTitle, Tooltip } from "reactstrap";
import { copyToClipBoard, documentFormater, formatSolicitationStatus } from "../../../utils/Util";
import { formatCentsToRealStr } from "../../../utils/Monetary";
import { formatBr } from "../../../utils/Date";
import { BackButton, ContentTable, MyTable } from "../../theme/Global";
import { SOLICITATIONSTATUS } from "../../../utils/Constants";
import ArrowBack from "../../../assets/icons/arrowBack.png";

const SolicitationLine = ({ id, title, value }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return <>
    <tr id={`Tooltip-${id}`} onClick={() => {
      copyToClipBoard(value)
    }}>
      <td>{title}</td>
      <td>{value}</td>
      {/* <td>
        <Button
          color="secondary"
          onClick={() => {
            copyToClipBoard(value)
          }}
        >
          Copiar
        </Button>

        <span style={{ textDecoration: "underline", color: "blue" }} href="#" >tooltip</span>
      </td> */}
    </tr>
    <Tooltip
      placement="top"
      isOpen={tooltipOpen}
      target={`Tooltip-${id}`}
      toggle={toggle}
    >
      Clique para copiar
    </Tooltip>
  </>
};

const SolicitationDetailComponent = ({
  solicitation,
  setObservation,
  onGoBack,
  onUpdateStatus,
}) => (
  <>
    <ContentTable>
      <Row>
        <Col>
          <BackButton
            // color="link"
            onClick={() => {
              onGoBack();
            }}
          >
            <img src={ArrowBack} alt="voltar" />
            Voltar
          </BackButton>
        </Col>

      </Row>
      <Row>
        <Col>
          <h1>Detalhes da solicitação</h1>
          <br/>
        </Col>
      </Row>
      <Row>

        <Col>
          <Row>
            <Col>
              <h5>Ações</h5>

              {solicitation.status === SOLICITATIONSTATUS.REGISTRED ?
                (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="info"
                          onClick={() => {
                            onUpdateStatus(solicitation.solicitationId, SOLICITATIONSTATUS.STARTED);
                          }}
                        >
                          Em análise
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : ''}

              {solicitation.status === SOLICITATIONSTATUS.STARTED || solicitation.status === SOLICITATIONSTATUS.PENDING ?
                (
                  <>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label>Observação</label>
                          <textarea className="form-control"
                            placeholder="Observação"
                            name="name"
                            rows={5}
                            onChange={(e) => setObservation(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" style={{ textAlign: 'right' }}>

                        <Button
                          color="warning"
                          onClick={() => {
                            onUpdateStatus(solicitation.solicitationId, SOLICITATIONSTATUS.PENDING);
                          }}
                        >
                          Pendente
                        </Button>

                        <Button
                          style={{ marginLeft: '5px' }}
                          color="danger"
                          onClick={() => {
                            onUpdateStatus(solicitation.solicitationId, SOLICITATIONSTATUS.REJECTED);
                          }}
                        >
                          Rejeitar
                        </Button>

                        <Button
                          style={{ marginLeft: '5px' }}
                          color="success"
                          onClick={() => {
                            onUpdateStatus(solicitation.solicitationId, SOLICITATIONSTATUS.APPROVED);
                          }}
                        >
                          Aprovar
                        </Button>
                      </Col>
                    </Row>
                  </>
                ) : ''}
            </Col>


          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <h5>Histórico de atualizações</h5>
              <MyTable>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th>Observação</th>
                  </tr>
                </thead>
                <tbody>
                  {solicitation.histories != null ? solicitation.histories.map((item) => (
                    <tr>
                      <td>{item.solicitationHistoryId}</td>
                      <td>{formatBr(item.register)}</td>
                      <td>{formatSolicitationStatus(item.status)}</td>
                      <td>{item.observation}</td>
                    </tr>
                  )) : ""}
                </tbody>
              </MyTable>
            </Col>
          </Row>
        </Col>

        <Col>
          <h5>Solicitação</h5>
          <MyTable>
            <tbody>
              {SolicitationLine({ id: "1", title: "Identificador", value: solicitation.solicitationId })}
              {SolicitationLine({ id: "2", title: "Data", value: formatBr(solicitation.register) })}
              {SolicitationLine({ id: "3", title: "Status", value: formatSolicitationStatus(solicitation.status) })}
              {SolicitationLine({ id: "4", title: "Valor", value: `R$ ${formatCentsToRealStr(solicitation.value)}` })}
              {SolicitationLine({ id: "5", title: "Parcelas", value: solicitation.plots })}
              {SolicitationLine({ id: "6", title: "Nome do Solicitante", value: solicitation.person.name })}
              {SolicitationLine({ id: "7", title: "Documento do Solicitante", value: documentFormater(solicitation.person.document) })}
              {solicitation.person.cellphone !== null && solicitation.person.cellphone !== "" ? SolicitationLine({ id: "8", title: "Celular do Solicitante", value: solicitation.person.cellphone }) : ""}
              {solicitation.person.email !== null && solicitation.person.email !== "" ? SolicitationLine({ id: "9", title: "Email do Solicitante", value: solicitation.person.email }) : ""}
              {solicitation.requirements != null && solicitation.requirements !== undefined && solicitation.requirements.length > 0
                ? <>
                  <tr>
                    <td colSpan="2">
                      <h5>Documentos</h5>
                    </td>
                  </tr>
                  {
                    solicitation.requirements.map((item, index) => (
                      item.url != null && item.url !== undefined && item.url !== "" ? <></> :
                        SolicitationLine({ id: `solicitationRequirements${index}`, title: item.requirement.text, value: item.value })
                    ))
                  }
                </> : ""}
            </tbody>

          </MyTable>

        </Col>


      </Row>

      {
        solicitation.requirements != null ?
          solicitation.requirements.map((item) =>
            item.url != null && item.url !== undefined && item.url !== "" ?

              (() => {
                const splited = item.name.split(".");
                const extension = String(splited[splited.length - 1]).toLocaleLowerCase();
                return <div style={{ width: (extension === "pdf" ? "100%" : "30%"), float: "left", padding: "5px" }}>
                  <Card>
                    {
                      extension === "pdf" ?
                        <iframe src={item.url} style={{ height: "60vh", width: "100%" }} title={item.name}><p>{item.name}</p></iframe> :
                        <img id={`reqImg${item.solicitationRequirementId}`} src={item.url} alt="logo" />
                    }
                    <CardBody>
                      <CardTitle tag="h5">{item.requirement.text}</CardTitle>
                      <Button
                        color="secondary"
                        onClick={async () => {
                          const xhr = new XMLHttpRequest();
                          xhr.onload = function () {
                            const reader = new FileReader();
                            reader.onloadend = function () {
                              const link = document.createElement("a");
                              link.setAttribute("href", reader.result);
                              link.setAttribute("download", item.name);
                              link.click();
                            }
                            reader.readAsDataURL(xhr.response);
                          };
                          xhr.open('GET', item.url);
                          xhr.responseType = 'blob';
                          xhr.send();
                        }}
                      >
                        Baixar
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              })()
              : <></>
          ) : <></>
      }

    </ContentTable>
  </>
);

export default SolicitationDetailComponent;
