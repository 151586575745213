import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import HomeComponent from "./HomeComponent";
import { Logger } from "../../../utils/Logger";

@inject("UIStore", "UserStore")
@observer
class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    const { UIStore, UserStore } = props;
    try {
      UIStore.getCurrentMarkdown();
      if (
        UIStore.currentMarkdown == null ||
        UIStore.currentMarkdown === undefined ||
        UIStore.currentMarkdown === ""
      ) {
        UIStore.setCurrentMarkdown(UserStore.menus.home.content);
      }
    } catch (e) {
      Logger(this, e);
    }
  }

  render() {
    const { UIStore, UserStore, history } = this.props;
    return (
      <HomeComponent
        history={history}
        leftNavBarIsOpen={UIStore.leftNavBarIsOpen}
        currentMarkdown={UIStore.currentMarkdown}
        onMouseEnter={()=>{
          if(!UserStore.isLogged()){
            window.location.reload();
          }
        }}
      />
    );
  }
}

export default HomeContainer;
