import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Table } from '../../../../molecules/Table/Table';
import { ContentTable } from '../../../../theme/Global';

import add from "../../../../../assets/icons/add.png";
import { DefaultColumnFilter } from '../../../../molecules/Table/Filters';
import { formatBr } from '../../../../../utils/Date';

const MovementList = ({ movements, onMaintain }) => (<>
    <ContentTable>
        <Row>
            <Col>
                <h1>Movimentos</h1>
            </Col>

            <Col style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                <Button color="primary" onClick={()=>onMaintain(undefined)} style={{ "width": "46px", "height": "46px" }}>
                    <img alt="Adicionar" src={add} style={{ "height": "20px", "width": "20px" }} />
                </Button>
            </Col>
        </Row>
        <Row>
            <Col>
                <ContentTable>
                    <Table columns={React.useMemo(
                        () => [
                            {
                                Header: 'Data de registro',
                                accessor: 'register',
                                Filter: DefaultColumnFilter,
                                filter: 'hardText',
                                myCustomRender: (value) => formatBr(value, false, true),
                                customCompare: (originalObject, value) => formatBr(originalObject.register, false, true).includes(value)
                            },
                            { Header: 'Local', accessor: 'local.name', minWidth: 81, Filter: DefaultColumnFilter },
                            { Header: 'Categoria', accessor: 'categoryDescription', minWidth: 81, Filter: DefaultColumnFilter },
                            { Header: 'Quantidade', accessor: 'quantity', minWidth: 10, Filter: DefaultColumnFilter },
                        ],
                        []
                    )}
                        data={movements}
                        onLinePressed={(index) => {
                            onMaintain(index)
                        }} />
                </ContentTable>
            </Col>
        </Row>
    </ContentTable>
</>);

export default MovementList;

// const s = {
//     "movementId": 79,
//     "register": "2021-11-24T14:33:52.26973",
//     "update": "2021-11-24T14:33:52.269741",
//     "productId": 28,
//     "product": {
//         "productId": 28,
//         "description": "Batata palha tradicional Elma Chips - 110g",
//         "group": null,
//         "groupId": null,
//         "register": "2021-11-17T12:57:37.091321",
//         "value": 699,
//         "quantity": 0,
//         "taxPlan": null,
//         "resultPlan": null,
//         "interest": null,
//         "iconUrl": null,
//         "paymentFrequencies": null,
//         "paymentFrequencyMessage": null,
//         "paymentWayMessage": null,
//         "paymentValueMessage": null,
//         "owner": 2,
//         "code": "7892840818319",
//         "name": "Bata Palha Tradicional",
//         "error": null
//     },
//     "localId": 1,
//     "local": {
//         "localId": 1,
//         "personId": 2,
//         "name": "Triacom Tecnologia",
//         "address": "Av. Rep. Argentina, 1336 - Sala 905 - Vila Izabel, Curitiba - PR, 80620-010"
//     },
//     "quantity": 20,
//     "personId": 2,
//     "type": 2,
//     "typeDescription": "Débito",
//     "category": 4,
//     "categoryDescription": "Venda"
// };