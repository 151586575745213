export const URL_AUTH_BASE_PATH = process.env.REACT_APP_AUTH_URL;

export const URL_ACCOUNT_BASE_PATH = process.env.REACT_APP_ACCOUNT_URL;

export const URL_PERSON_BASE_PATH = process.env.REACT_APP_PERSON_URL;

export const URL_FUNDING_BASE_PATH = process.env.REACT_APP_FUNDING_URL;

export const URL_PROCESS_BASE_PATH = process.env.REACT_APP_PROCESS_URL;

export const URL_PAY_BASE_PATH = process.env.REACT_APP_PAY_URL;

export const URL_MARKETPLACE_BASE_PATH = process.env.REACT_APP_MARKETPLACE_URL;

export const AUTH_DATA = {
  client_id: "Triacom", // This is the client_id
  client_secret: "TriacomSecret0101", // This is the client_secret
  grant_type: "password",
  scope: "triacom",
};

export const TOKEN_KEY = "@triacom-web";
export const TOKEN_EXPIRES_KEY = "@triacom-exp-web";
export const MENUS_KEY = "@triacom-menus-web";
export const MENUS_CATEGORY_KEY = "@triacom-menus-category-web";
export const CURRENT_MARKDOWN = "@triacom-currentMarkdown";

export const EXTERNAL_TAB = "1";
export const INTERNAL_TAB = "2";

export const EXPIRE_CAHCE = 60000;

// eslint-disable-next-line no-undef
export const IS_MOBILE = () => window.innerWidth < 768;

export const FixedPages = (key) => {
  switch (key) {
    case "taxSimulator":
      return "simulate";

      case "taxSimulatorAdmin":
        return "simulateadmin"; 

    case "processList":
      return "solicitation";

    case "personManager":
      return "personManager";

    case "fileUploader":
      return "fileUploader";

      case "marketplaceProduct":
        return "marketplace/product"

    default:
      return "";
  }
};

export const SOLICITATIONSTATUS = {
  REGISTRED: 0,
  PENDING: 1,
  STARTED: 2,
  REJECTED: 3,
  APPROVED: 4,
};
