import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { MarkerPlaceGetAllGroups, MarkerPlaceGetManintainGroup } from '../../../services/MarketplaceService';
import add from "../../../assets/icons/add.png";
import AlertComponent from '../../atoms/AlertComponent';


/*

{
    "groupId": 2,
    "description": "Bebidas",
    "register": "2021-11-17T09:15:42.078956",
    "taxPlan": null,
    "resultPlan": null,
    "interest": 0,
    "loggedPersonId": 0,
    "iconUrl": null,
    "products": null
},

*/

const MyDiv = styled.div`
    transition-duration: 500ms;
    background: ${({ isOpen }) => isOpen ? "#E0E0E0" : "transparent"};
    border-radius: 5px;
    padding: ${({ isOpen }) => isOpen ? 15 : 0}px;
    padding-top: 0;
    padding-bottom: 0;
    
    .content {
        margin: 15px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
    }
`

class MarkeplaceGroupProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            isOpen: false,
            name: ""
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const list = await MarkerPlaceGetAllGroups();
        this.setState({
            name: "",
            isOpen: false,
            groups: list
        });
    }


    render() {
        const { groups, isOpen, name } = this.state;
        const { groupId, onChange, disabled } = this.props;
        return (<MyDiv isOpen={isOpen}>
            <FormGroup row>
                <Row>
                    <Label
                        for="exampleSelect"
                        sm={2}
                    >
                        Grupo
                    </Label>
                </Row>
                <Row>
                    <Col sm={10}>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            value={`${groupId}`}
                            disabled={disabled}
                            onChange={(event) => {
                                this.setState({ isOpen: false, name: "" })
                                onChange(event.target.value);
                            }}
                        >
                            <option value="0">
                                Selecione um grupo
                            </option>)
                            {
                                groups.map((iten, index) => <option value={iten.groupId}>
                                    {iten.description}
                                </option>)
                            }
                        </Input>
                    </Col>
                    <Col sm={2} style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({ isOpen: !isOpen, name: "" })
                            }}
                            style={{ "width": "40px", "height": "40px", padding: "0px" }}
                            disabled={disabled}
                        >
                            <img alt="Adicionar" src={add} style={{ "height": "20px", "width": "20px" }} />
                        </Button>
                    </Col>
                </Row>

                {
                    isOpen && <Row className="content">
                        <Col sm={9}>
                            <FormGroup>
                                <label>Nome do novo grupo</label>
                                <Input
                                    name="name"
                                    placeholder="Nome"
                                    value={name}
                                    onChange={(event) => {
                                        this.setState({ name: event.target.value })
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col sm={3} style={{ position: "relative" }}>
                            <Button
                                color="primary"
                                style={{ marginBottom: "20px", position: "absolute", bottom: "0" }}
                                onClick={async () => {
                                    const response = await MarkerPlaceGetManintainGroup(name, (err) => {
                                        AlertComponent.error(err)
                                    });
                                    if (response != null && response !== undefined) {
                                        this.load();
                                    }
                                }}>
                                Salvar
                            </Button>
                        </Col>

                    </Row>
                }
            </FormGroup>
        </MyDiv>);
    }
}

export default MarkeplaceGroupProduct;