/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

const topMenuHeight = 60; // px

export const Board = styled.div`
transition-duration: 500ms;
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundDarkMediun};
  display: grid;
  grid-template-columns: auto 100% ;
  /* grid-template-rows: auto ${({ topMenuOpen }) => topMenuOpen ? `10vh` : `auto`}; */
  grid-template-areas: 
    "header header"
    "sidebar main";
`;

export const OverContent = styled.div`
  background-color: ${({ theme }) => theme.background};
  color:  ${({ theme }) => theme.contrastBackground};
  position: relative;

  .name{
    position: absolute;
    display: grid;
    justify-content: end;
    width: ${({ sideMenuOpen }) => sideMenuOpen ? "calc(100% - 250px)" : "100%"};
    transition-duration: 500ms;
    .con{
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 5px;
      padding-left: 10px;
      border-bottom-left-radius: 10px;
      background-color: ${({ theme }) => theme.backgroundDarkMediun};
      color: ${({ theme }) => theme.contrastBackgroundDarkMediun};
      font-size: 0.8em;
      z-index: 1000;
    }
    
  }
`

export const Content = styled.div`
  transition-duration: 500ms;
  grid-area: main;
  height:  ${({ topMenuOpen }) => topMenuOpen ? `10vh` : `calc(100vh - ${topMenuHeight}px)`};
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
  color:  ${({ theme }) => theme.contrastBackground};
  border-top-left-radius: 10px;
  padding: 15px;
  padding-top: 30px;
  width: calc(100% - ${({ sideMenuOpen }) => sideMenuOpen ? `250px` : `0px`});
  iframe{
    width: 100% !important;
  }
  ${({ isMarkdown }) => isMarkdown ? `
  div:first-child, span {
    height: 100%;
    width: 100%;
  }
  ` : ``}
  
`;

export const TopMenu = styled.div`
  grid-area: header;
  background-color: transparent;
  min-height: ${topMenuHeight}px;
  width: 100vw;

  .TopMenuGrid{
    background-color: transparent;
    min-height: ${topMenuHeight}px;
    width: 100vw;
    display: grid;

    grid-template-columns: 10% 70% 20%;
    @media only screen and (max-width: 700px) {
      grid-template-columns: 15% 70% 15%;
    }
    @media only screen and (min-width: 1200px) {
      grid-template-columns: 6% 84% 10%;
    }

    .justify-end{
      justify-self: end;
    }
  }

  
`;

export const TopMenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  width: ${topMenuHeight}px;
  height: ${topMenuHeight}px;
  padding: 20px;
  padding-top: 15px;
  outline: none;
  transition-duration: 500ms;
  display: inline-block;
  opacity: ${({ disabled }) => disabled ? 0 : 1};
`

export const CollapsedTopMenu = styled.div`
  display: grid;
  justify-items: end;
  grid-template-areas: 
    "header"
    "main";
`;

export const ExpandedTopMenu = styled.div`
    grid-area: sidebar;
    height: ${({ isOpen }) => isOpen ? `80vh` : `0px`};
    width: 100%;
    overflow: auto;
    transition-duration: 500ms;
`

export const SideMenu = styled.div`
  direction: rtl;
  grid-area: sidebar;
  background-color: transparent;
  width: ${({ sideMenuOpen }) => sideMenuOpen ? `250px` : `0px`};
  overflow-x: hidden;
  overflow-y: auto;
  transition-duration: 500ms;
  height:  ${({ topMenuOpen }) => topMenuOpen ? `10vh` : `calc(100vh - ${topMenuHeight+100}px)`};
  ul{
    width: 250px;
  }
`;

export const SideMenuList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TopMenuList = styled.ul`
  direction: ${({ isHorizontal }) => isHorizontal ? "ltr" : "rtl"};
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  li{
    float: left;
  }
`;


export const SideMenuListItem = styled.li`
  outline: none;
  margin-right:  ${({ selected, expanded }) => selected || expanded ? 0 : 5}px;
  border-radius: 10px ${({ selected, expanded, isHorizontal }) => isHorizontal ? "10px 10px" : selected || expanded ? "0px 0px" : "10px 10px"} 10px;
  transition-duration: 500ms;
  background-color: ${({ theme, selected, subItem }) => selected ? theme.background : subItem ? "transparent" : theme.backgroundDarkMediun};
  color: ${({ theme, selected }) => selected ? theme.contrastBackground : theme.contrastBackgroundDarkMediun};
  position: relative;

  margin-top: ${({ selected }) => selected ? 15 : 0}px;
  margin-bottom: ${({ selected }) => selected ? 15 : 0}px;

  &:hover{
    background-color: ${({ theme, selected }) => selected ? theme.background : theme.backgroundDarkMediun};
  }

  &:before {
    content: "";
    position: absolute;
    background-color: transparent;
    right: 0;
    top: -24px;
    height: 25px;
    width: 25px;
    border-bottom-right-radius: 15px;
    box-shadow: 15px 15px 0px 0px ${({ theme, selected, isHorizontal }) => !isHorizontal && selected ? theme.background : "transparent"};
  }

  &:after {
    content: "";
    position: absolute;
    background-color: transparent;
    right: 0px;
    bottom: -24px;
    height: 25px;
    width: 25px;
    border-top-right-radius: 15px;
    box-shadow: 15px -15px 0px 0px ${({ theme, selected, isHorizontal }) => !isHorizontal && selected ? theme.background : "transparent"};
  }

  div {
    padding: 5px;
    padding-left: 15px;
    outline: none;
  }
`;

export const ExpandedMenu = styled.div`
  direction: rtl;
  max-height:  ${({ expanded }) => expanded ? "500vh" : "0px"};
  /* min-height:  ${({ expanded, itemsCount }) => expanded ? (itemsCount * 40) : 0}px; */
  overflow: hidden;
  padding: 0px !important;
  padding-bottom: ${({ subItem, selected }) => selected && subItem ? "5" : "0"}px !important;
  margin: 0%;
  border-radius: 10px 0px 0px 10px;
  transition-duration: 500ms;

  z-index: ${({ isLateral }) => isLateral ? "auto" : 99999};
  position: ${({ isLateral }) => isLateral ? "unset" : "fixed"};
  width: ${({ isLateral }) => isLateral ? "100%" : "20vw"};
  @media only screen and (max-width: 600px) {
    width: calc(100% - 10px);
    border-top-right-radius: 0px;
  }
  
  .SideMenuListItem{
    width: calc(100% - 10px);
    padding-left: 30px;
  }

  ul{
    list-style: none;
    width: 100%;
    padding: 0px;
  }
`;