import api from "./api";
import { URL_FUNDING_BASE_PATH } from "../utils/Constants";

export class UserService {
  getPlans = async () => {
    const url = `${URL_FUNDING_BASE_PATH}/simulator/planKeys`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getSimulation = async ({ value, interest, plan }) => {
    const url = `${URL_FUNDING_BASE_PATH}/simulator/${value}/${interest}/${plan}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };
}

const userService = new UserService();
export default userService;
