import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from "styled-components";

import a from './menuLight.png'
import b from './menu.png'


const MyImage = styled.img`
    width: 100%;
    height: 100%;
`;

const MyDiv = styled.div`
    width: 100%;
    height: 100%;
    transition-duration: 500ms;
    svg{
        width: 100%;
        height: 100%;
    }
`;

@inject("UIStore")
@observer
class IconMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { UIStore } = this.props;
        // return (<MyImage src={UIStore.darkTheme ? a : b} alt="menu" />);
        return <MyDiv>
            <svg version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt"
                height="512.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill={UIStore.themes[UIStore.isDarkTheme ? "darkTheme" : "lightTheme"].contrastBackgroundDarkMediun}
                    stroke="none">
                    <path d="M350 4847 c-174 -59 -291 -185 -334 -358 -40 -165 6 -329 129 -454
                64 -65 121 -102 207 -132 51 -17 139 -18 2208 -18 2069 0 2157 1 2208 18 86
                30 143 67 207 132 123 125 169 289 129 454 -43 175 -168 307 -343 361 -62 20
                -95 20 -2205 20 l-2141 -1 -65 -22z"/>
                    <path d="M434 3050 c-206 -30 -366 -175 -419 -377 -65 -247 92 -511 350 -590
                57 -17 152 -18 2195 -18 2043 0 2138 1 2195 18 258 79 415 343 350 590 -45
                173 -170 305 -345 364 -53 17 -143 18 -2165 19 -1160 1 -2133 -2 -2161 -6z"/>
                    <path d="M420 1233 c-102 -17 -197 -68 -275 -148 -123 -125 -169 -289 -129
                -454 43 -175 168 -307 343 -361 62 -20 95 -20 2201 -20 2106 0 2139 0 2201 20
                175 54 300 186 343 361 60 245 -76 487 -330 585 -47 18 -120 19 -2189 20
                -1177 1 -2151 -1 -2165 -3z"/>
                </g>
            </svg>
        </MyDiv>
    }
}

export default IconMenu;