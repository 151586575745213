/* eslint-disable import/prefer-default-export */
/* eslint-disable react/button-has-type */
import { ButtonGroup, Input, Button, Row, Col } from 'reactstrap';
import React, { useState } from 'react';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table'
import { matchSorter } from 'match-sorter'
import { CheckBoxContent, CustomSelector, ExpandedButton, Header, InnerPagination, MainContent, NavigationOptions, TableFilter } from './styles';
import Checkbox from '../../atoms/Checkbox';
import { MyTable } from '../../theme/Global';

export const Table = ({ columns, data, onSearch, showPagination, onGetAditive, getErrorRow, topRightArea, onLinePressed }) => {
    const filterTypes = React.useMemo(
        () => ({
            hardText: (rows, id, filterValue) => {
                const finalList = [];
                const mapColumns = (myColumns, myId) => {
                    for (let i = 0; i < myColumns.length; i++) {
                        if (
                            myColumns[i].accessor === myId &&
                            myColumns[i].customCompare !== undefined
                        )
                            return myColumns[i].customCompare
                        if (
                            myColumns[i].columns !== undefined &&
                            myColumns[i].columns.length > 0
                        ) {
                            const a = mapColumns(myColumns[i].columns, myId)
                            if (a !== undefined && (i + 1) >= myColumns.length)
                                return a;
                        }
                    }
                    return undefined;
                }
                const customCompare = mapColumns(columns, id[0]);
                if (customCompare !== undefined)
                    for (let i = 0; i < rows.length; i++)
                        if (customCompare(rows[i].original, filterValue))
                            finalList.push(rows[i])

                try {
                    if (finalList.length <= 0 && onSearch != null && onSearch !== undefined)
                        onSearch(id, filterValue);
                } catch (e) { }
                return finalList;
            },
            fuzzyText: (rows, id, filterValue) => {
                const a = matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
                try {
                    if (a.length <= 0 && onSearch != null && onSearch !== undefined)
                        onSearch(id, filterValue);
                } catch (e) { }
                return a;
            },
            text: (rows, id, filterValue) => rows.filter(row => row.values[id] !== undefined
                ? String(row.values[id])
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true),
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        state: { pageIndex, pageSize },
        allColumns,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: React.useMemo(() => ({ Filter: <></> }), []),
            filterTypes
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    const [expanded, setExpanded] = useState(false);

    return (
        <>
            {
                showPagination ? <MyNavigationOptions
                    gotoPage={gotoPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    canNextPage={canNextPage}
                    pageCount={pageCount}
                    pageSize={pageSize}
                    setPageSize={setPageSize} /> : <></>
            }

            <Row>
                <Col xs="2">
                    <CustomSelector expanded={expanded} onMouseLeave={() => { setExpanded(false) }}>
                        <ExpandedButton type="button" onClick={() => {
                            setExpanded(!expanded)
                        }}>
                            Colunas
                        </ExpandedButton>
                        <Header expanded={expanded}>
                            <ul>
                                {allColumns.map(column => (
                                    column.id === "options" ? <></> :
                                        <li>
                                            <label>
                                                <CheckBoxContent key={column.id}>
                                                    <Checkbox {...column.getToggleHiddenProps()} />
                                                    {/* <input type="checkbox"  />{' '} */}
                                                    {column.Header}

                                                </CheckBoxContent>
                                            </label>

                                        </li>
                                ))}
                            </ul>

                        </Header>
                    </CustomSelector>
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    {
                        topRightArea != null && topRightArea !== undefined ? topRightArea() : <></>
                    }
                </Col>

            </Row>
            <MainContent>
                <MyTable {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    column.headerOnly ? <></> :
                                        <th >
                                            <TableFilter expanded={true}>
                                                <div>{
                                                    column.canFilter ||
                                                        column.customCompare !== undefined ?
                                                        column.render('Filter') : null}</div>
                                            </TableFilter>
                                        </th>
                                ))}
                            </tr>
                        ))}
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        colSpan={
                                            column.headerOnly ? column.columns.length : 1}
                                        style={{ minWidth: `${column.minWidth !== undefined && column.minWidth > 0 ? column.minWidth : 180}px` }}>
                                        <h6
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{ textAlign: column.headerOnly ? "center" : "start" }} >
                                            {column.render('Header')}&nbsp;
                                            {
                                                column.id === "options" || column.headerOnly ? <></> :
                                                    <>
                                                        <span>
                                                            {column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <>&#128317;</>
                                                                    : <>&#128316;</>
                                                                : <>▶️</>}
                                                        </span>
                                                    </>
                                            }
                                        </h6>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {/* {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))} */}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            const myEvents = {
                                onclick: () => { }
                            }
                            return (
                                <>
                                    <tr {...row.getRowProps()} onClick={() => {
                                        if (onLinePressed != null && onLinePressed !== undefined)
                                            onLinePressed(row.index)
                                        if (myEvents.onclick != null && myEvents.onclick !== undefined)
                                            myEvents.onclick();
                                    }} className={getErrorRow != null && getErrorRow !== undefined && getErrorRow(row.original) ? `error` : ``}>
                                        {row.cells.map((cell, index) => (
                                            <td
                                                style={{ minWidth: `${cell.column.minWidth !== undefined ? cell.column.minWidth : 150}px` }}
                                                {...cell.getCellProps()}>
                                                {
                                                    cell.column.isTableIndex ?
                                                        `${(i + 1)}` :
                                                        cell.column.myCustomRender != null &&
                                                            cell.column.myCustomRender !== undefined ?
                                                            cell.column.myCustomRender(cell.value, cell.row.original) :
                                                            cell.render('Cell')
                                                }
                                            </td>
                                        )
                                        )}
                                    </tr>
                                    {onGetAditive != null && onGetAditive !== undefined ? onGetAditive(row.original, myEvents) : <></>}
                                </>
                            )
                        })}
                    </tbody>
                </MyTable>
            </MainContent>
            <MyNavigationOptions
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageSize={pageSize}
                setPageSize={setPageSize} />

        </>
    )
}

const MyNavigationOptions = ({
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    pageIndex,
    pageOptions,
    canNextPage,
    pageCount,
    pageSize,
    setPageSize,
}) => (
    <NavigationOptions>
        <div className="NavigationOptionsContent">
            <ButtonGroup >
                <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</Button>
                <Button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</Button>

                <InnerPagination>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </InnerPagination>

                <Button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</Button>
                <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</Button>
            </ButtonGroup>
        </div>
        <div className="NavigationOptionsContent">
            <Input
                style={{ width: "min-content" }}
                type="select"
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
            >
                {[5, 10, 20, 30, 40, 50].map(mpageSize => (
                    <option key={mpageSize} value={mpageSize}>
                        Exibir {mpageSize} linhas
                    </option>
                ))}
            </Input>
        </div>
        <div className="NavigationOptionsContent">
            Vá para página:{' '}
            <Input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                    const mpage = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(mpage)
                }}
                style={{ width: '100px' }}
            />

        </div>


    </NavigationOptions>
)