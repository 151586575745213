import React, { useContext } from "react";
import {
  Card as DefaultCard,
  CardHeader as DefaultCardHeader,
  CardBody as DefaultCardBody,
} from "reactstrap";

import { ThemeContext } from "styled-components";

export const Card = (props) => {
  const { backgroundDarkMediun, contrastBackgroundDarkMediun } = useContext(ThemeContext);
  return (
    <DefaultCard
      {...props}
      style={{
        backgroundColor: backgroundDarkMediun,
        borderColor: backgroundDarkMediun,
        color: contrastBackgroundDarkMediun,
      }}
    />
  );
};

export const CardHeader = (props) => {
  const { backgroundDarkMediun, contrastBackgroundDarkMediun } = useContext(ThemeContext);
  return (
    <DefaultCardHeader
      {...props}
      style={{
        backgroundColor: backgroundDarkMediun,
        borderColor: backgroundDarkMediun,
        color: contrastBackgroundDarkMediun,
      }}
    />
  );
};

export const CardBody = (props) => {
  const { tertiary, textTertiary } = useContext(ThemeContext);
  return (
    <DefaultCardBody
      {...props}
      style={{
        backgroundColor: tertiary,
        borderColor: tertiary,
        color: textTertiary,
      }}
    />
  );
};
