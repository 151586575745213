import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import ListPersonComponent from "./ListPersonComponent";
import personManagerService from "../../../services/PersonManagerService";
import { validaCpf, validateDocument, phoneValidator, mailValidator, showChat } from "../../../utils/Util";
import AlertComponent from "../../atoms/AlertComponent";
import AddPersonContainer from "./addPerson/AddPersonContainer";
import Dasboard from "../../molecules/Dashboard/Dashboard";

const pageList = 0;
const pageRegister = 1;

@inject("UIStore", "UserStore")
@observer
class PersonManagerContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            people: [],
            activationCodeList: [],
            activationCodePersonList: [],
            isValidDocument: false,
            isDefalutName: false,
            document: "",
            birthDate: "",
            cellphone: "",
            name: "",
            email: "",
            page: pageList
        };
    }

    componentDidMount() {
        this.search()
    }

    search = async () => {
        const people = await personManagerService.getPeople("", "");
        const activationCodeList = await personManagerService.getActivationCode();
        const activationCodePersonList = [{
            personId: 0,
            personName: "Todos",
        }];

        for (let i = 0; i < activationCodeList.length; i++) {
            const element = activationCodeList[i];
            let exist = false;
            for (let j = 0; j < activationCodePersonList.length; j++) {
                if (activationCodePersonList[j].personId === activationCodeList[i].personId) {
                    exist = true;
                    break;
                }
            }
            if (!exist) {
                activationCodePersonList.push({ personId: activationCodeList[i].personId, personName: activationCodeList[i].personName })
            }
        }

        this.setState({
            people,
            activationCodeList,
            activationCodePersonList,
            isLoading: false
        });
    }

    render() {
        const { UserStore, history } = this.props;
        const { isLoading, page, people, activationCodeList, activationCodePersonList, isValidDocument, name, document, birthDate, isDefalutName } = this.state
        return <>
            <>
                {
                    page === pageList &&
                    <ListPersonComponent
                        people={people}
                        isLoading={isLoading}
                        onAdd={() => {
                            this.setState({
                                page: pageRegister
                            })
                        }} />
                }
                {
                    page === pageRegister &&
                    <AddPersonContainer
                        isLoading={isLoading}
                        activationCodeList={activationCodeList}
                        activationCodePersonList={activationCodePersonList}
                        isValidDocument={isValidDocument}
                        defalutName={name}
                        isDefalutName={isDefalutName}
                        defalutDocument={document}
                        defalutBirthDate={birthDate}
                        onBack={() => {
                            this.setState({
                                isLoading: true,
                                activationCodeList: [],
                                activationCodePersonList: [],
                                isValidDocument: false,
                                document: "",
                                birthDate: "",
                                cellphone: "",
                                name: "",
                                email: "",
                                page: pageList
                            });
                            this.search();
                        }}
                        onChange={(event) => {
                            this.setState({
                                [event.target.name]: event.target.value
                            })
                        }}
                        onValidateDocument={async (callback) => {
                            this.setState({
                                isLoading: true,
                            })
                            const response = await personManagerService.validateBeforeRegistering({ document: document.replace(/[^0-9]/g, ""), birthDate })
                            const newState = {
                                isLoading: false,
                                isValidDocument: response ? response.success : false,
                                name: response ? response.name : undefined,
                                isDefalutName: response.name != null && response.name !== undefined && response.name !== ""
                            };
                            callback(newState)
                            this.setState(newState)
                        }}

                        onRegister={async (servicesSelected, activationSelected,) => {
                            const { cellphone, email } = this.state
                            let newBirthDate = "";
                            let servicesSelectedList = "";
                            const errors = [];
                            if (!validateDocument(this.state.document)) errors.push("Informe um documento válido.");
                            if (!phoneValidator(cellphone)) errors.push("Informe um celular válido.");
                            if (!mailValidator(email)) errors.push("Informe um e-mail válido.");
                            if (name == null || name === undefined || name === "")
                                errors.push("Informe seu nome.");
                            if (
                                activationSelected.activationCodeId == null ||
                                activationSelected.activationCodeId === undefined ||
                                activationSelected.activationCodeId === ""
                            )
                                errors.push("Informe seu código de ativação.");

                            if (errors.length > 0) {
                                for (let i = 0; i < errors.length; i++) {
                                    AlertComponent.error(errors[i]);
                                }
                                return;
                            }

                            this.setState({
                                isLoading: true,
                            })

                            for (let i = 0; i < servicesSelected.length; i++) {
                                servicesSelectedList += `${JSON.parse(servicesSelected[i]).serviceId}${i === (servicesSelected.length - 1) ? `` : `;`}`
                            }

                            if (validaCpf(this.state.document)) {
                                newBirthDate = birthDate.replace(/\s/g, '').split("/");
                                newBirthDate = `${newBirthDate[2]}-${newBirthDate[1]}-${newBirthDate[0]}`;
                            }

                            const response = await personManagerService.register({
                                document: this.state.document.replace(/[^0-9]/g, ""),
                                name: this.state.name,
                                cellphone: cellphone.replace(/[^0-9]/g, ""),
                                email,
                                activationCode: `${activationSelected.code}`,
                                birthDate: newBirthDate,
                                services: servicesSelectedList,
                                registerPersonId: UserStore.getId()
                            }, false);

                            if (response.success === true) {
                                AlertComponent.sucess(response.message)
                                this.setState({
                                    isLoading: true,
                                    activationCodeList: [],
                                    activationCodePersonList: [],
                                    isValidDocument: false,
                                    document: "",
                                    birthDate: "",
                                    cellphone: "",
                                    name: "",
                                    email: "",
                                    page: pageList
                                })
                                this.search();
                            } else {
                                AlertComponent.error(response.message)
                                this.setState({
                                    isLoading: false,
                                })
                            }
                        }}
                    />
                }
            </>

            {showChat()}
        </>
    }
}

export default PersonManagerContainer;