import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from "react-router-dom";
import auth from '../../services/Auth';
import Dasboard from '../molecules/Dashboard/Dashboard';
import HomeContainer from '../pages/Home';
import PersonManagerContainer from '../pages/PersonManager/PersonManagerContainer';
import SolicitationContainer from '../pages/Process/SolicitationContainer';
import SimulateContainer from '../pages/Simulate/SimulateContainer';
import FileUploaderContainer from '../pages/FileUploader/FileUploaderContainer';
import Marketplace from '../pages/Marketplace/Marketplace';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            auth.isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

@inject("UserStore")
@observer
class MainPage extends React.Component {
    render() {
        const url = window.location.href.toLowerCase();
        const { UserStore, history } = this.props;
        return (
            <>
                <Dasboard
                    history={history}
                    onMouseEnter={() => {
                        if (!UserStore.isLogged()) {
                            window.location.reload();
                        }
                    }}
                    isMarkdown={url.includes("home")}
                >
                    <Switch>
                        <PrivateRoute path="/home" component={HomeContainer} />
                        <PrivateRoute path="/simulate" component={SimulateContainer} />
                        <PrivateRoute path="/simulateadmin" component={SimulateContainer} />
                        <PrivateRoute path="/solicitation" component={SolicitationContainer} />
                        <PrivateRoute path="/personManager" component={PersonManagerContainer} />
                        <PrivateRoute path="/fileUploader" component={FileUploaderContainer} />
                        <PrivateRoute path="/marketplace" component={Marketplace} />
                        <Route path="*" component={() => <h1>Page not found</h1>} />
                    </Switch>
                </Dasboard>
            </>
        );
    }
}

export default MainPage;