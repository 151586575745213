import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import MarkeplaceCategory from '../../../../molecules/marketplace/MarkeplaceCategory';
import MarkeplaceLocals from '../../../../molecules/marketplace/MarkeplaceLocals';


/*

{
    "productId": 26,
    "localId": 1,
    "quantity": 100,
    "category": 1
}

*/

class MovementMaintain extends Component {
    constructor(props) {
        super(props);
        const { movementSelected, productId } = props;
        if (
            movementSelected != null &&
            movementSelected !== undefined &&
            movementSelected.movementId !== undefined &&
            movementSelected.movementId > 0
        )
            this.state = {
                movementId: movementSelected.movementId,
                localId: movementSelected.localId,
                quantity: movementSelected.quantity,
                category: movementSelected.category,
                newMovement: false
            }
        else
            this.state = {
                localId: 0,
                quantity: 0,
                category: 0,
                movementId: 0,
                newMovement: true,
            }
    }

    checProps = (nextProps) => {
        const { movementSelected, productId } = nextProps;
        const {
            localId,
            quantity,
            category,
            newMovement,
            movementId
        } = this.state;

        if (
            movementSelected != null &&
            movementSelected !== undefined &&
            movementSelected.movementId !== undefined &&
            movementSelected.movementId > 0 &&
            (
                movementId !== movementSelected.movementId || (
                    productId === 0 &&
                    localId === 0 &&
                    quantity === 0 &&
                    category === 0 &&
                    newMovement === true
                )
            )
        ) {
            this.setState({
                localId: movementSelected.localId,
                quantity: movementSelected.quantity,
                category: movementSelected.category,
                movementId: movementSelected.movementId,
                newMovement: false
            })
        }
        else if (
            (
                movementSelected == null ||
                movementSelected === undefined ||
                movementSelected.movementId == null ||
                movementSelected.movementId === undefined
            ) && !newMovement
        ) {
            this.setState({
                localId: 0,
                quantity: 0,
                category: 0,
                newMovement: true
            })
        }

    }

    mRegister = () => {
        const { register, productId } = this.props;

        const {
            localId,
            quantity,
            category
        } = this.state;

        register({
            productId: parseInt(productId, 10),
            localId: parseInt(localId, 10),
            quantity: parseInt(quantity, 10),
            category: parseInt(category, 10),
        })
    }

    render() {
        this.checProps(this.props)
        const { isOpen, closeModal, isLoadingSave } = this.props;
        const {
            newMovement,
            category,
            movementId,
            localId,
            quantity,
        } = this.state;
        return (<>
            <Modal
                isOpen={isOpen}
                style={{ color: "#000" }}
            >
                <ModalBody>
                    <Row>
                        <Col sm={11}>
                            <h3>
                                {
                                    newMovement ?
                                        "Novo movimento" : `Movimento ${movementId}`
                                }
                            </h3>
                        </Col>
                        <Col sm={1} style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                            <Button onClick={closeModal} disabled={isLoadingSave}>
                                X
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <MarkeplaceLocals
                        localId={localId}
                        disabled={!newMovement}
                        onChange={(value) => {
                            this.setState({
                                localId: parseInt(value, 10)
                            })
                        }} />

                    <MarkeplaceCategory
                        disabled={!newMovement}
                        categoryId={category}
                        onChange={(value) => {
                            this.setState({
                                category: parseInt(value, 10)
                            })
                        }} />

                    <FormGroup>
                        <label>Quantidade</label>
                        <Input
                            disabled={!newMovement}
                            name="quantity"
                            placeholder="Quantidade"
                            type="number"
                            value={`${quantity}`}
                            onChange={(event) => {
                                const val = event.target.value;
                                this.setState({
                                    quantity: val != null && val !== undefined && val.replace(/[^0-9]/g, "").trim() !== "" ?
                                        parseInt(val.replace(/[^0-9]/g, ""), 10) : 0
                                })
                            }}
                        />
                    </FormGroup>

                </ModalBody>
                <ModalFooter>

                    <Button onClick={closeModal} disabled={isLoadingSave}>
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={this.mRegister} disabled={isLoadingSave}>
                        Salvar
                    </Button>
                </ModalFooter>
            </Modal>
        </>);
    }


}



export default MovementMaintain;