import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import ReactLoading from "react-loading";
import { Section, SectionMinified } from "./styles";

const getColor = (contrastColor) => {
  const { contrastBackground, contrastBackgroundDark, contrastBackgroundDarkMediun } = useContext(ThemeContext);
  switch (contrastColor) {
    case 1:
      return contrastBackgroundDark;

    case 2:
      return contrastBackgroundDarkMediun;

    default:
      return contrastBackground;
  }
}

const LoadingComponent = ({ minified, contrastColor }) => minified === true ? (
  <SectionMinified>
    <ReactLoading type="bars" color={getColor(contrastColor)} />
  </SectionMinified>
) : (
  <Section>
    <ReactLoading type="bars" color={getColor(contrastColor)} />
  </Section>
)

export default LoadingComponent;
