import moment from "moment";

export const formatDDMM = (date) => moment(new Date(date)).format("DD/MM");

export function formatBr(date, hideTime, broken) {
  const myDate = moment(new Date(date));

  if(broken === true)
    return `${myDate.format("DD/MM/YYYY")} ${hideTime ? `` : `às\n${myDate.format("HH:mm")}`}`;

  return `${myDate.format("DD/MM/YYYY")} ${hideTime ? `` : `às ${myDate.format("HH:mm")}`}`;
};