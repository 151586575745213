import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";


import LoginContainer from "./ui/pages/Login";
import MainPage from "./ui/templates/MainPage";
import { PrivateRoute } from "./utils/PrivateRoute";


const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={LoginContainer} />
      <PrivateRoute path="/" component={MainPage} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;