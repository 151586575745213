import React from 'react';
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from '../../../utils/PrivateRoute';
import ProductContainer from './Products/ProductContainer';

const Marketplace = () => (<>
    <Switch>
        <PrivateRoute path="/marketplace/product" component={ProductContainer} />
        <Route path="*" component={() => <h1>Page not  12</h1>} />
    </Switch>
</>);

export default Marketplace;