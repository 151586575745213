import React from "react";
import { FormGroup, Label, Button, Table, Alert, Row, Col } from "reactstrap";
import { Select } from "../../atoms/Select";
import { Input } from "../../atoms/Input";
import { Main, Content, ContentTable, MyTable } from "../../theme/Global";
import { formatReal } from "../../../utils/Monetary";
import Dasboard from "../../molecules/Dashboard/Dashboard";
import LoadingComponent from "../../atoms/LoadingComponent";
import { showChat } from "../../../utils/Util";

const SimulateComponent = ({
  isAdmin,
  plans,
  simulation,
  onSimulate,
  setValue,
  setPlan,
  setInterest,
  data,
  message,
  history,
  onMouseEnter,
  isLoading,
}) => {
  const hasSimulation =
    simulation != null && simulation !== undefined && simulation.length > 0;
  if (data.plan === "" && plans.length > 0) setPlan(plans[0].key);
  return (
    <>
      {

        isLoading ? <>
          <LoadingComponent />
        </> :
          <>
            <Row>
              <Col sm="12" md="4">
                <Content hasSimulation={hasSimulation}>
                  <h1>Simulação</h1>

                  <FormGroup>
                    <Label for="exampleSelect">Plano de pagamento</Label>
                    <Select
                      type="select"
                      name="select"
                      onChange={(e) => {
                        setPlan(e.target.value);
                      }}
                    >
                      {plans.map((item) => (
                        <option selected={data.plan === item.key} value={item.key}>
                          {item.description}
                        </option>
                      ))}
                    </Select>
                  </FormGroup>

                  <FormGroup>
                    <Label for="exampleSelect">Valor</Label>

                    <Input
                      value={formatReal(data.value)}
                      onInput={({ target }) => {
                        let tmp = parseInt(
                          target.value.replace(/[^0-9]/g, ""),
                          10
                        );
                        if (tmp >= 100000000000000000000) tmp = 100000000000000000000;
                        target.value = formatReal(tmp);
                      }}
                      onChange={(event) => {
                        try {
                          const num = parseInt(
                            event.target.value.replace(/[^0-9]/g, ""),
                            10
                          );
                          if (Number.isNaN(num)) setValue(0);
                          else setValue(num);
                        } catch (e) {
                          setValue(0);
                        }
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="selectLabel">Juros</Label>
                    <Select
                      type="select"
                      name="select"
                      onChange={(e) => {
                        setInterest(parseInt(e.target.value, 10));
                      }}
                    >
                      <option selected={data.interest === 2} value={2}>
                        Vendedor
                      </option>
                      <option selected={data.interest === 1} value={1}>
                        Comprador
                      </option>
                    </Select>
                  </FormGroup>

                  <Button
                    color="primary"
                    onClick={() => {
                      onSimulate();
                    }}
                  >
                    Simular
                  </Button>

                  {message !== undefined && (
                    <Alert
                      color={message.type === 0 ? `danger` : `info`}
                      style={{ marginTop: "20px" }}
                    >
                      {message.text}
                    </Alert>
                  )}

                </Content>
              </Col>
              <Col sm="12" md="8" style={{ overflowX: "auto" }}>
                {hasSimulation && (
                  <Content>
                    <ContentTable>
                      <MyTable>
                        <thead>
                          <tr>
                            <th>Parcelas</th>
                            <th>Valor da parcela</th>
                            <th>Encargos</th>
                            <th>Valor cobrado</th>
                            <th>CET mensal</th>
                            <th>Valor líquido</th>
                            <th>Desconto mensal</th>
                            <th>Taxa efetiva</th>
                            { isAdmin ? (
                            <><th>Margin bruta</th>
                            <th>Custo financeiro</th>
                            <th>Margem</th>
                            </>) :
                            (<></>)}
                          </tr>
                        </thead>
                        <tbody>
                          {simulation.map((item) => typeof item.plan === "string" || item.plan >= 0 ? (
                            <tr>
                              <td>{item.plan}</td>
                              <td>{formatReal(item.plotValue)}</td>
                              <td>{item.descriptionInterest}</td>
                              <td>{formatReal(item.endValue)}</td>
                              <td>{item.cet}</td>
                              <td>{formatReal(item.liquidValue)}</td>
                              <td>{item.discountPercent}</td>
                              <td>{item.effectiveRatePercent}</td>
                              { isAdmin ? (
                                <><td>{item.grossMargin}</td>
                                <td>{item.cost}</td>
                                <td>{item.marginPercent}</td>
                                </>) :
                              (<></>)}
                            </tr>
                          ) : <></>)}
                        </tbody>
                      </MyTable>
                    </ContentTable>
                  </Content>
                )}
              </Col>
            </Row>

          </>
      }
      {showChat()}
    </>
  );
};

export default SimulateComponent;
