import React from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { formatBr } from "../../../utils/Date";
import { formatReal } from "../../../utils/Monetary";
import { documentFormater, formatSolicitationStatus } from "../../../utils/Util";
import { DefaultColumnFilter, MonetaryFilter, SelectFilter } from "../../molecules/Table/Filters";
import { Table } from "../../molecules/Table/Table";
import { ContentTable } from "../../theme/Global";



const SolicitationComponent = ({
  solicitations,
  onDetail,
  onSearch
}) => (
  <>
    <ContentTable>
      <Row>
        <Col>
          <h1>Solicitações</h1>
          <br />
        </Col>
      </Row>
      <Table columns={React.useMemo(
        () => [
          {
            minWidth: 80,
            accessor: 'options', myCustomRender: (value, item) => <Button
              color="secondary"
              onClick={() => {
                onDetail(item);
              }}
            >
              Detalhes
            </Button>
          },
          { Header: 'ID', accessor: 'solicitationId', minWidth: 81, Filter: DefaultColumnFilter },
          {
            Header: 'Data',
            accessor: 'register',
            myCustomRender: (value) => formatBr(value, false, true),
            Filter: DefaultColumnFilter,
            filter: 'hardText',
            customCompare: (originalObject, value) => formatBr(originalObject.register, false, true).includes(value)
          },
          { Header: 'Status', accessor: 'status', Filter: SelectFilter, filter: 'status', myCustomRender: (value) => formatSolicitationStatus(value), minWidth: 100 },
          { Header: 'Valor', accessor: 'value', Filter: DefaultColumnFilter, myCustomRender: (value) => `R$ ${formatReal(value)}`, minWidth: 120 },
          { Header: 'Parcelas', accessor: 'plots', Filter: DefaultColumnFilter, minWidth: 81 },
          { Header: 'Solicitante', accessor: 'person.name', Filter: DefaultColumnFilter, },
          { Header: 'Documento', accessor: 'person.document', Filter: DefaultColumnFilter, myCustomRender: (value) => documentFormater(value) },
          { Header: 'Celular', accessor: 'person.cellphone', Filter: DefaultColumnFilter, myCustomRender: (value) => value !== null && value !== "" ? value : "-" },
        ],
        []
      )} data={solicitations} onSearch={onSearch} />
      {/* <table>
        <tbody>
          {solicitations && solicitations.map((item) => (
            <tr>
              <td>{item.plots}</td>
              <td>{item.person.name}</td>
              <td>{documentFormater(item.person.document)}</td>
              <td>{item.person.cellphone !== null && item.person.cellphone !== "" ? item.person.cellphone : "-"}</td>
              <td><Button
                color="link"
                onClick={() => {
                  onDetail(item);
                }}
              >
                Detalhes
              </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </ContentTable>
  </>
);

export default SolicitationComponent;
