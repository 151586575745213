import { has } from 'mobx';
import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';
import { formatBr } from '../../../utils/Date';
import { formatCentsToRealStr, formatReal } from '../../../utils/Monetary';
import { copyToClipBoard, formatBarcode } from '../../../utils/Util';
import { DefaultColumnFilter, SelectFilter2 } from '../../molecules/Table/Filters';
import { Table } from '../../molecules/Table/Table';
import { Content, ContentTable } from '../../theme/Global';
import { ExpansibleDiv } from './Styles';

function ResponseComponent({ response, onReset }) {
    return (<>
        <Content>
            <ContentTable>
                <Table columns={React.useMemo(
                    () => [
                        // { Header: 'Linha', accessor: 'tableIndex', isTableIndex: true },
                        { Header: 'Linha', accessor: 'line', minWidth: 81, },
                        {
                            Header: 'Valor',
                            accessor: 'value',
                            Filter: DefaultColumnFilter,
                            myCustomRender: (value) => `R$ ${formatReal(value)}`,
                            minWidth: 120
                        },
                        {
                            Header: 'Status',
                            accessor: 'status',
                            myCustomRender: (value, original) => {
                                if (original.error != null && original.error !== undefined && original.error !== "") {
                                    return "Erro (Clique para expandir)"
                                }

                                switch (value) {
                                    case 0:
                                    case 1:
                                        return "Criado";
                                    case 2:
                                        return "Erro (Clique para expandir)";
                                    default:
                                        return "";
                                }
                            },
                            Filter: SelectFilter2,
                            minWidth: 120
                        }
                    ],
                    []
                )}
                    data={response}
                    onGetAditive={(original, myEvents) => <Expansible original={original} myEvents={myEvents} />}
                    getErrorRow={(original) => {
                        if (
                            original.status === 3 ||
                            (
                                original.error != null &&
                                original.error !== undefined &&
                                original.error !== ""
                            )
                        )
                            return true;
                        for (let i = 0; i < original.histories.length; i++) {
                            const history = original.histories[i];
                            if (
                                history.status === 3 ||
                                (
                                    history.error != null &&
                                    history.error !== undefined &&
                                    history.error !== ""
                                )
                            )
                                return true;

                            for (let j = 0; j < original.histories.length; j++) {
                                const charge = history.charges[j];
                                if (
                                    charge.status === 3 ||
                                    (
                                        charge.error != null &&
                                        charge.error !== undefined &&
                                        charge.error !== ""
                                    )
                                )
                                    return true;
                            }
                        }
                        return false;
                    }}
                    topRightArea={() => <>
                        <Button className="btn-fill" color="primary" onClick={onReset}>
                            Enviar um novo arquivo
                        </Button>
                    </>}
                />
            </ContentTable>
        </Content>
    </>);
}

function Expansible({ original, myEvents }) {
    const [isOpen, setIsOpen] = useState(false);
    myEvents.onclick = () => {
        setIsOpen(!isOpen);
    }
    return (
        <tr className="noHover" asd={123}>
            <td colSpan="4" style={{ padding: "0", borderBottom: isOpen ? "1px solid #0F131A" : "0px" }}>
                <ExpansibleDiv isOpen={isOpen}>
                    <div className="content">
                        {
                            original.error != null && original.error !== undefined && original.error !== "" ? <p>{original.error}</p> : <></>
                        }
                        {
                            original.histories != null && original.histories !== undefined && original.histories.length > 0 ? original.histories.map((history) => {
                                const hasHistory = history.charges != null && history.charges !== undefined;
                                return <>
                                    {
                                        hasHistory && history.charges.length > 0 ? history.charges.map((charge, index) => {
                                            const { chargeId, barcode, expirationDate, status, value, linkUrl } = charge
                                            const type = barcode == null || barcode === undefined ? "Link" : "Boleto";
                                            return <>
                                                <PaymentItem title={type} value={chargeId} />
                                                <PaymentItem title="Vencimento" value={formatBr(expirationDate, true)} />
                                                <PaymentItem title={`Valor do ${type}`} value={`R$ ${formatCentsToRealStr(value)}`} />
                                                <PaymentItem title="Status" value={(() => {
                                                    switch (status) {
                                                        case 2:
                                                            return "Pago";
                                                        case 3:
                                                            return "Erro";
                                                        default:
                                                            return "Aguardando pagamento";
                                                    }
                                                })()} />
                                                {
                                                    barcode != null && barcode !== undefined && <PaymentItem title="Código de barras" value={formatBarcode(barcode)} />
                                                }
                                                {
                                                    linkUrl != null && linkUrl !== undefined && <PaymentItem title="Link" value={<>
                                                        <a href={linkUrl} style={{ padding: "20px" }}>Link</a>
                                                        <Button size="sm" color="primary" onClick={() => {
                                                            copyToClipBoard(linkUrl)
                                                        }}>Copiar</Button>
                                                    </>} />
                                                }
                                                {
                                                    index === (history.charges.length - 1) ? <></> : <hr />
                                                }

                                            </>
                                        }) : hasHistory && history.status === 3 ? <p>{history.error}</p> : <p>Sem boletos ou links para mostrar</p>
                                    }
                                </>

                            }) :
                                <p>Sem boletos ou links para mostrar</p>
                        }
                    </div>
                </ExpansibleDiv>
            </td>
        </tr>
    );
}

function PaymentItem({ title, value }) {
    return (
        <Row>
            <Col>{title}</Col>
            <Col>{value}</Col>
        </Row>
    );
}

export default ResponseComponent;