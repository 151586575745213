/* eslint-disable no-undef */
import { observable, action, makeAutoObservable } from "mobx";

import { IS_MOBILE, CURRENT_MARKDOWN } from "../utils/Constants";
import auth from "../services/Auth";

export class UIStore {
  @observable isDarkTheme = false;

  @observable themes = {};

  @observable leftNavBarIsOpen = false;

  @observable topNavBarIsOpen = false;

  @observable currentMarkdown = "";

  @observable width = window.innerWidth;

  @observable currentMenuSelected = "";

  @observable currentCategoryMenuSelected = "";

  constructor() {
    this.leftNavBarIsOpen = !IS_MOBILE();
    this.currentMarkdown = localStorage.getItem(CURRENT_MARKDOWN);
    document.getElementsByTagName("BODY")[0].onresize = this.onresize;
    this.isDarkTheme = localStorage.getItem("isDarkTheme") === "true";
    this.currentMenuSelected = localStorage.getItem("currentMenuSelected");
    this.currentCategoryMenuSelected = localStorage.getItem("currentCategoryMenuSelected");
    makeAutoObservable(this)
  }

  @action.bound
  changeCurrentMenuSelected = (menuSelected) => {
    this.currentMenuSelected = menuSelected;
    localStorage.setItem("currentMenuSelected", menuSelected)
  };

  @action.bound
  changeCurrentCategoryMenuSelected = (menuSelected) => {
    this.currentCategoryMenuSelected = menuSelected;
    localStorage.setItem("currentCategoryMenuSelected", menuSelected)
  };

  @action.bound
  changeThemesMode = (isDark) => {
    this.isDarkTheme = isDark;
    localStorage.setItem("isDarkTheme", this.isDarkTheme)
  };

  @action.bound
  changeThemes = (darkTheme, lightTheme) => {
    this.themes = {
      darkTheme,
      lightTheme
    }
  };

  @action.bound
  onresize = () => {
    this.width = window.innerWidth;
    if (this.width > 450 && this.topNavBarIsOpen === true)
      this.topNavBarIsOpen = false;
    if (this.width < 450 && this.leftNavBarIsOpen === true)
      this.leftNavBarIsOpen = false;
  };

  @action.bound
  closeLeftNavBar = () => {
    this.leftNavBarIsOpen = false;
  };

  @action.bound
  openLeftNavBar = () => {
    this.leftNavBarIsOpen = true;
  };

  @action.bound
  closeTopNavBar = () => {
    this.topNavBarIsOpen = false;
  };

  @action.bound
  openTopNavBar = () => {
    this.topNavBarIsOpen = true;
  };

  @action.bound
  setCurrentMarkdown = (markdown) => {
    if (auth.isAuthenticated()) {
      this.currentMarkdown = markdown;
      // eslint-disable-next-line no-undef
      localStorage.setItem(CURRENT_MARKDOWN, markdown);
    } else {
      auth.logout();
      window.location.replace("/");
    }
  };

  @action.bound
  getCurrentMarkdown = () => {
    this.currentMarkdown = localStorage.getItem(CURRENT_MARKDOWN);
  }
}

export const clearCacheUiStore = () => {
  localStorage.setItem("isDarkTheme", false)
  localStorage.setItem("currentMenuSelected", "")
  localStorage.setItem("currentCategoryMenuSelected", "")
}

const uIStore = new UIStore();
export default uIStore;
