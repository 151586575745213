export const formatCentsToRealStr = (value) => {
  if (value == null || value === undefined)
    return "0,00";
  if (typeof value === 'string') {
    return (parseInt(value.replace(/[^0-9]/g, ""), 10) / 100).toFixed(2).replace(".", ",");
  }

  return (value / 100).toFixed(2).replace(".", ",");
};

export const formatReal = (int) => {
  let tmp = int;
  if (typeof int === "string") tmp = parseInt(int.replace(/[^0-9]/g, ""), 10);
  if (Number.isNaN(tmp)) tmp = 0;
  return (tmp / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+,)/g, "$1.");
};