/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-destructuring */
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { inject, observer } from "mobx-react";

import { DropFile, SendButtonArea } from "./Styles";
import LoadingComponent from "../../atoms/LoadingComponent/LoadingComponent";


@inject("UIStore")
@observer
class FileUploaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hoverWithFile: false,
        };

    }

    dragEnter = (e) => {
        e.preventDefault();
        this.setState({
            hoverWithFile: true
        })
    }

    dragLeave = (e) => {
        e.preventDefault();
        this.setState({
            hoverWithFile: false
        })
    }

    fileDrop = (e, myFiles) => {
        e.preventDefault();
        const { onChangeFile } = this.props;
        onChangeFile(myFiles)
        this.setState({
            hoverWithFile: false
        })
    }

    onClick = (e) => {
        const inf = document.getElementById("inputFile");
        inf.click();
    }

    render() {
        const { hoverWithFile } = this.state;
        const { files, isLoading, onSubmit } = this.props;
        return isLoading ? (
            <LoadingComponent />
        ) : (
            <>
                <input id="inputFile" type="file" style={{ display: "none" }} onChange={(e) => this.fileDrop(e, e.target.files)} />
                <Row>
                    <Col sm={{ size: '3', offset: 9 }} style={{ display: "flex", flexFlow: "row-reverse" }}>
                        <Button color="link" onClick={() => {
                            window.open("https://triacomstore.blob.core.windows.net/images/portal/Exemplo_de_arquivo_de_Cobrança_Recorrente.csv", "_blank")
                        }}>Arquivo de exemplo</Button>
                    </Col>
                </Row>
                <DropFile className="drop-container"
                    role="button"
                    tabIndex={0}
                    onDragEnter={this.dragEnter}
                    onDragLeave={this.dragLeave}
                    onDrag={this.dragLeave}
                    onDrop={(e) => {
                        e.preventDefault();
                        this.fileDrop(e, e.dataTransfer.files)
                    }}
                    onDragOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onClick={this.onClick}
                    onKeyDown={() => { }}
                    hoverWithFile={hoverWithFile}>

                    <div>
                        <h2>
                            {hoverWithFile ? "Solte" : "Arraste"} o arquivo aqui ou <Button className="btn-fill" color="primary">
                                Selecione
                            </Button>
                        </h2>
                    </div>
                    {
                        files == null || files === undefined || files.length <= 0 ?
                            (<></>) : (<>
                                <div className="ListName">
                                    <h6>Arquivo{files.length > 1 ? "s" : ""} selecionado{files.length > 1 ? "s" : ""}</h6>
                                    <ul>
                                        {(() => {
                                            const names = [];
                                            for (let i = 0; i < files.length; i++)
                                                names.push(files[i].name);
                                            return names;
                                        })().map((item) => <li><p>{item}</p></li>)}
                                    </ul>

                                </div>
                            </>)
                    }

                </DropFile>
                {
                    files == null || files === undefined || files.length <= 0 ?
                        (<></>) : (<SendButtonArea>
                            <Button className="btn-fill" color="primary" onClick={onSubmit}>
                                Enviar
                            </Button>
                        </SendButtonArea>)
                }
            </>
        )
    }
}

export default FileUploaderComponent;
