import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from "styled-components";

import a from './rightArrow.png'
import b from './downArrow.png'


const MyImage = styled.img`
    width: 10px;
    height: 10px;
`;

@inject("UIStore")
@observer
class IconExpanded extends React.Component {
    render() {
        const { UIStore, expanded, style } = this.props;
        return (<MyImage style={style} src={expanded ? b : a} alt="Expandir" />);
    }
}

export default IconExpanded;