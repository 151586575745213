import React, { Component } from "react";
import { inject } from "mobx-react";
import LoginComponent from "./LoginComponent";
import AlertComponent from "../../atoms/AlertComponent";
import LoadingComponent from "../../atoms/LoadingComponent";
import { clearCacheUiStore } from "../../../stores/UIStore";

@inject("UserStore", "UIStore")
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    const { UserStore } = props;
    UserStore.logout();
    this.goLogin = this.goLogin.bind(this);
  }

  async goLogin() {
    localStorage.clear();
    clearCacheUiStore();
    this.setState({
      isLoading: true,
    });
    const { UserStore, history } = this.props;
    const { document, password } = this.state;
    await UserStore.login(document, password);
    this.setState({
      isLoading: false,
    });
    if (UserStore.isLogged()) {
      history.push("/home");
    } else AlertComponent.error("Falha ao realizar login", "danger");
  }

  render() {
    const { isLoading } = this.state;
    return isLoading === true ? (
      <LoadingComponent contrastColor={2} />
    ) : (
      <LoginComponent
        onChange={(e) => {
          this.setState({
            [e.target.name]: e.target.value,
          });
        }}
        goLogin={this.goLogin}
      />
    );
  }
}

export default LoginContainer;
