/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const SendButtonArea = styled.div`
    width: 100%;
    padding-top: 10px;
    button{
        width: 100%;
    }
`

export const DropFile = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${({ hoverWithFile }) => hoverWithFile ? "rgba(0,0,0, 0.9)" : "rgba(0,0,0, 0.5)"};
    color: #fff;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition-duration: 500ms;

    * {
        pointer-events: none;
    }
    
    .ListName{
        max-height: 200px;
        overflow-y: auto;
        text-align: center;
        padding: 20px;
        background-color: #000;
        border-radius: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        p{
            margin: 0;
        }
    }
`;

export const ExpansibleDiv = styled.div`
    ${({ isOpen }) => isOpen ? "" : "height: 0;"}
    ${({ isOpen }) => isOpen ? "" : "margin: 0;"}
    ${({ isOpen }) => isOpen ? "" : "padding: 0;"}
    overflow: hidden;
    transition-duration: 500ms;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.contrastBackground};
    

    .content{
        padding: 15px;
        border: 0.5px solid #ccc;
    }
`