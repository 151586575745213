import axios from "axios";
import auth from "./Auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
});

export const tokenlessApi = axios.create({
  baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
});

api.interceptors.request.use(async (config) => {
  const token = auth.getToken();
  if (
    token &&
    (auth.isAuthenticated() ||
      (config.url.includes("connect/token") && !auth.isAuthenticated()))
  ) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
