import React, { useState } from "react";
import { Button, FormGroup, Label, Row, Col } from "reactstrap";
import { validaCpf, validateDocument } from "../../../../utils/Util";
import { TextInputMask } from "../../../atoms/Input";
import LoadingComponent from "../../../atoms/LoadingComponent/LoadingComponent"
import ArrowBack from "../../../../assets/icons/arrowBack.png";

import { BackButton } from "../../../theme/Global";

const Step1Document = ({
    activationCodeList,
    activationCodePersonList,
    isValidDocument,
    onValidateDocumentInterceptor,
    onChange,
    defalutName,
    defalutDocument,
    defalutBirthDate,
    onBack,
    isLoading,
}) => {

    if (isLoading)
        return <LoadingComponent />

    const [name, setName] = useState(defalutName);
    if (name !== defalutName)
        setName(defalutName)
    const [document, setDocument] = useState(defalutDocument);
    if (document !== defalutDocument)
        setDocument(defalutDocument)
    const [birthDate, setBirthDate] = useState(defalutBirthDate);
    if (birthDate !== defalutBirthDate)
        setBirthDate(defalutBirthDate)
    return <>

        {/* <Row>
            <Col style={{ "display": "flex", "flex-direction": "row" }}>
                <TransparentButton style={{ "marginRight": "2%" }} onClick={onBack}><img alt="voltar" src={arrowBack} style={{ "height": "25px", "width": "25px" }} /></TransparentButton>
                <h1>Nova Conta</h1>
            </Col>
        </Row> */}

        <Row>
            <Col>
                <BackButton
                    // color="link"
                    onClick={onBack}
                >
                    <img src={ArrowBack} alt="voltar" />
                    Voltar
                </BackButton>
            </Col>

        </Row>
        <Row>
            <Col>
                <h1>Nova Conta</h1>
                <br />
            </Col>
        </Row>

        <FormGroup>
            <Label for="exampleSelect">Documento</Label>
            <TextInputMask
                id="document"
                name="document"
                type="text"
                mask={["999.999.999-99", "99.999.999/9999-99"]}
                placeholder="CPF/CNPJ"
                value={document}
                disabled={isValidDocument}
                onChange={(event) => {
                    setDocument(event.target.value)
                    try {
                        onChange(event)
                    } catch (_) { }
                }}
            />
        </FormGroup>
        {
            validaCpf(document) ? <>
                <FormGroup>
                    <label>Data de nascimento</label>
                    <TextInputMask
                        id="birthDate"
                        name="birthDate"
                        disabled={isValidDocument}
                        onChange={(event) => {
                            setBirthDate(event.target.value)
                            try {
                                onChange(event)
                            } catch (_) { }
                        }}
                        type="text"
                        mask={["99/99/9999"]}
                        placeholder="Data de nascimento"
                        value={birthDate}
                    />
                </FormGroup>
            </> : <></>
        }

        <Button
            className="btn-fill"
            color="primary"
            type="submit"
            onClick={onValidateDocumentInterceptor}
            disabled={!validateDocument(document)}
        >
            Continuar
        </Button>
    </>
};

export default Step1Document;