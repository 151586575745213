import React from 'react';
import LogoBBanking from './bbanking/LogoBBanking';
import LogoBSV from './bsv/LogoBSV';
import LogoCoopermobil from './coopermobil/LogoCoopermobil';
import LogoFacieg from './facieg/LogoFacieg';
import LogoGenial from './genial/LogoGenial';
import LogoGoBox from './goBox/LogoGoBox'; 
import LogoPagueaki from './pagueaki/LogoPagueaki';
import LogoSaudeSA from './saudesa/LogoSaudeSA';
import LogoTriacom from './triacom/LogoTriacom';

const Logo = () => {
    const url = window.location.href.toLowerCase();
    if (url.includes("saudesa"))
        return <LogoSaudeSA />
    if (url.includes("bsv"))
        return <LogoBSV />
    if (url.includes("bbank"))
        return <LogoBBanking />
    if (url.includes("genial"))
        return <LogoGenial />
    if (url.includes("coopermobil"))
        return <LogoCoopermobil />
    if (url.includes("facieg"))
        return <LogoFacieg />
    if (url.includes("pagueaki"))
        return <LogoPagueaki />
    if (url.includes("gobox"))
        return <LogoGoBox />
    return <LogoTriacom />
    // return <LogoBSV />
}

export default Logo;