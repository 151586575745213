/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from "styled-components";

export const MySelect = styled.select`
position: relative;
  appearance: none;
  outline: none;
  margin-bottom: 20px;
  padding: 10px 18px;

  font-size: 0.75rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  line-height: 1.42857;
  background-clip: padding-box;
  border: none;
  border-radius: 0.4285rem;
  /* border-end-end-radius: 0;
  border-top-right-radius: 0; */
  box-shadow: none;
  background-color: ${({ theme }) => theme.fieldColor}de;
  color: ${({ theme }) => theme.fieldContrastColor};
`;

const GridDiv = styled.label`
  display: grid;
  grid-template-columns: auto 38px;
`

const IconDiv = styled.div`
  height: 38px;
  width: 38px;
  background-color: blue;
  float: left;
`

export class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // const { id } = this.props;
    // const a = id != null && id !== undefined ? id : `MySelect${new Date().getTime()}`;
    return (<GridDiv>

      <MySelect {...this.props} />

    </GridDiv>);
  }
}

