import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import SimulateComponent from "./SimulateComponent";
import LoadingComponent from "../../atoms/LoadingComponent";

@inject("UIStore", "UserStore")
@observer
class SimulateContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      message: undefined,
      plans: [],
      simulation: [],
      value: 0,
      plan: "",
      interest: 2,
    };
  }

  componentDidMount() {
    this.getPlans();
  }

  async getPlans() {
    this.setState({
      isLoading: true,
      message: undefined,
    });
    const { UserStore } = this.props;
    const data = await UserStore.getPlans();
    this.setState({ plans: data, isLoading: false });
  }

  async getSimulation(data) {
    this.setState({
      isLoading: true,
      message: undefined,
    });
    try {
      const { UserStore } = this.props;
      const response = await UserStore.getSimulation(data);
      this.setState({
        simulation: response,
        isLoading: false,
        message:
          response === null || response === undefined
            ? { type: 0, text: `Erro ao realizar a operação` }
            : response.length <= 0
              ? { type: 1, text: `Não há resultados para mostrar` }
              : undefined,
      });
    } catch (_) { }
  }

  render() {
    const { UserStore, history } = this.props;
    const isAdmin = (history.location.pathname === "/simulateadmin");
    const {
      isLoading,
      plans,
      simulation,
      interest,
      value,
      plan,
      message,
    } = this.state;
    return (
      <>
        <SimulateComponent
          isAdmin={isAdmin}
          message={message}
          plans={plans}
          simulation={simulation}
          isLoading={isLoading}
          onMouseEnter={() => {
            if (!UserStore.isLogged()) {
              window.location.reload();
            }
          }}
          history={history}
          onSimulate={async () => {
            await this.getSimulation({ interest, value, plan });
          }}
          data={{ interest, value, plan }}
          setValue={(mValue) => {
            this.setState({
              value: mValue,
            });
          }}
          setPlan={(mValue) => {
            this.setState({
              plan: mValue,
            });
          }}
          setInterest={(mValue) => {
            this.setState({
              interest: mValue,
            });
          }}
        />

      </>
    );
  }
}

export default SimulateContainer;
