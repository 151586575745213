import axios from "axios";
import api, { tokenlessApi } from "./api";
import { URL_PERSON_BASE_PATH, URL_PAY_BASE_PATH, URL_ACCOUNT_BASE_PATH } from "../utils/Constants";
import authService from "./AuthService";
import { validaCpf } from "../utils/Util";
import AlertComponent from "../ui/atoms/AlertComponent";


export class PersonManagerService {
  getPeople = async (document, name) => {
    const url = `${URL_PERSON_BASE_PATH}/Person/personfilter`;// ?document=${document}&name=${name}`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  };

  getActivationCode = async () => {
    const url = `${URL_PAY_BASE_PATH}/ActivationCode`;
    try {
      const { data } = await api.get(url);
      return data;
    } catch (err) {
      return null;
    }
  }; 

  validateBeforeRegistering = async (info, renew) => {
    // if (true)
    //   return {
    //     success: true,
    //     name: "Teste"
    //   }
    let data = await authService.loginRegister(renew);
    if (data == null || data === undefined || data === "undefined") {
      data = await authService.loginRegister(true);
    }
    const { access_token } = data;

    if (access_token != null && access_token !== undefined) {
      const myApi = axios.create({
        baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
      });
      myApi.interceptors.request.use(async (config) => {
        if (access_token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config;
      });

      let url = `${URL_ACCOUNT_BASE_PATH}/Person/cnpjvalidate/${info.document}`;
      if (validaCpf(info.document)) {
        let bd = info.birthDate.replace(/\s/g, '').split("/");
        bd = `${bd[2]}-${bd[1]}-${bd[0]}`;
        url = `${URL_ACCOUNT_BASE_PATH}/Person/cpfvalidate/${info.document}/${bd}`;
      }

      data = null;
      let status = 401;

      try {
        const res = await myApi.get(url, info);
        status = res.status;
        data = res.data;
      } catch (_) { }

      if (status === 401 && renew !== true) {
        const d = await this.validateBeforeRegistering(info, true);
        return d;
      }
      if (status === 200) {
        if (data && !data.success) {
          AlertComponent.error(data.message)
        }
        return data;
      }
      AlertComponent.error(`Falha de comunicação: ${status}`)
      return null
    }
    return null;
  }

  register = async (info, renew) => {
    let loginRegisterResponse = await authService.loginRegister(renew);
    if (loginRegisterResponse == null && loginRegisterResponse === undefined) {
      loginRegisterResponse = await authService.loginRegister(true);
    }
    const { access_token } = loginRegisterResponse
    if (access_token != null && access_token !== undefined) {
      tokenlessApi.interceptors.request.use(async (config) => {
        if (access_token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${access_token}`;
        }
        return config;
      });

      const url = `${URL_ACCOUNT_BASE_PATH}/Person/v2`;
      try {
        const { data, status } = await tokenlessApi.post(url, info);
        if (status === 401 && !renew) {
          const d = await this.register(info, true);
          return d;
        }
        return data;
      } catch (err) {
        return null;
      }
    }
    return null;
  }

}

const personManagerService = new PersonManagerService();
export default personManagerService;