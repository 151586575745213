import { URL_MARKETPLACE_BASE_PATH } from "../utils/Constants";
import api from "./api";

export const MarkerPlaceGetAllProducts = async () => {
    const url = `${URL_MARKETPLACE_BASE_PATH}Product?owner=2`;
    try {
        const { data } = await api.get(url);
        return data;
    } catch (err) { }
    return [];
}

export const MarkerPlaceGetAllGroups = async () => {
    const url = `${URL_MARKETPLACE_BASE_PATH}Group?owner=2`;
    try {
        const { data } = await api.get(url);
        return data;
    } catch (err) { }
    return [];
}

export const MarkerPlaceGetManintainGroup = async (groupName, onError) => {

    if (groupName == null || groupName === undefined || groupName === "") {
        if (onError != null && onError !== undefined)
            onError("Nome do grupo não informado")
        return null
    }

    const url = `${URL_MARKETPLACE_BASE_PATH}Group/maintain`;
    try {
        const { data } = await api.post(url, {
            "description": groupName,
            "owner": 2
        });
        return data;
    } catch (err) { }
    return null;
}

export const MarkerPlaceGetManintainProduct = async (product, onError) => {
    if (product != null && product !== undefined) {
        const {
            name,
            code,
            value,
        } = product

        const isError_name = name == null || name === undefined || name === 0
        const isError_code = code == null || code === undefined || code === 0
        const isError_value = value == null || value === undefined || value === 0
        if (
            onError != null && onError !== undefined && (
                isError_name ||
                isError_code ||
                isError_value
            )
        ) {
            let err = ""
            if (isError_name) err = "Nome não informado"
            if (isError_code) err = "Código de barras não informado"
            if (isError_value) err = "Valor não informado"
            onError(err)
            return null
        }
    } else
        return null

    product.owner = 2;

    const url = `${URL_MARKETPLACE_BASE_PATH}product/maintain`;
    try {
        const { data } = await api.post(url, product);
        return data;
    } catch (err) { }
    return null;



}

export const MarkerPlaceGetAllMovements = async (productId) => {
    const url = `${URL_MARKETPLACE_BASE_PATH}Movement?productId=${productId}`;
    try {
        const { data } = await api.get(url);
        return data;
    } catch (err) { }
    return [];
}

export const MarkerPlaceGetManintainMovement = async (movement, onError) => {

    const isError_productId = movement.productId == null || movement.productId === undefined || movement.productId === 0
    const isError_localId = movement.localId == null || movement.localId === undefined || movement.localId === 0
    const isError_category = movement.category == null || movement.category === undefined || movement.category === 0
    const isError_quantity = movement.quantity == null || movement.quantity === undefined || movement.quantity === 0

    if (
        movement == null || movement === undefined ||
        isError_productId ||
        isError_localId ||
        isError_category ||
        isError_quantity
    ) {
        if (onError != null && onError !== undefined) {
            let err = ""
            if (isError_productId) err = "Produto não informado"
            if (isError_localId) err = "Local não informado"
            if (isError_category) err = "Categoria não informada"
            if (isError_quantity) err = "Quantidade não informada"
            onError(err)
        }

        return null
    }


    const url = `${URL_MARKETPLACE_BASE_PATH}Movement`;
    try {
        const { data } = await api.post(url, movement);
        return data;
    } catch (err) { }
    onError("Falha de cominicação")
    return null;
}

export const MarkerPlaceGetAllCategory = async () => {
    const url = `${URL_MARKETPLACE_BASE_PATH}category`;
    try {
        const { data } = await api.get(url);
        return data;
    } catch (err) { }
    return [];
}

export const MarkerPlaceGetAllLocals = async () => {
    const url = `${URL_MARKETPLACE_BASE_PATH}local`;
    try {
        const { data } = await api.get(url);
        return data;
    } catch (err) { }
    return [];
}

export const MarkerPlaceGetManintainLocal = async (local, onError) => {
    const isError_name = local.name == null || local.name === undefined || local.name.trim() === ""
    const isError_address = local.address == null || local.address === undefined || local.address.trim() === ""
    if (
        local == null || local === undefined ||
        isError_name || isError_address
    ) {
        if (onError != null && onError !== undefined) {
            let err = ""
            if (isError_name)
                err = "Nome não informado"
            if (isError_address)
                err = "Endereço não informado"
            onError(err)
        }
        return null
    }

    const url = `${URL_MARKETPLACE_BASE_PATH}local`;
    try {
        const { data } = await api.post(url, local);
        return data;
    } catch (err) { }
    return null;
}