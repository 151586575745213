import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import SolicitationComponent from "./SolicitationComponent";
import SolicitationDetailComponent from "./SolicitationDetailComponent";
import LoadingComponent from "../../atoms/LoadingComponent";
import { SOLICITATIONSTATUS } from "../../../utils/Constants";
import Dasboard from "../../molecules/Dashboard/Dashboard";
import { showChat } from "../../../utils/Util";

const pageList = 0;
const pageDetail = 1;

@inject("UIStore", "UserStore")
@observer
class SolicitationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      observation: null,
      message: undefined,
      solicitations: [],
      solicitation: {}
    };
  }

  async componentDidMount() {
    try {
      const { UserStore } = this.props;
      const response = await UserStore.getSolicitations(SOLICITATIONSTATUS.REGISTRED);
      this.setState({
        page: pageList,
        solicitations: response,
        isLoading: false,
        message:
          response === null || response === undefined
            ? { type: 0, text: `Erro ao realizar a operação` }
            : response.length <= 0
              ? { type: 1, text: `Não há resultados para mostrar` }
              : undefined,
      });
    } catch (_) { }
  }

  async onDetail(data) {
    const { UserStore } = this.props;
    this.setState({
      solicitation: await UserStore.getSolicitationById(data.solicitationId),
      page: pageDetail
    });
  }

  async onUpdateStatus(solicitationId, status) {
    this.setState({
      isLoading: true
    });
    const { observation } = this.state;
    const { history, UserStore } = this.props;
    const solicitationUpdate = {
      solicitationId,
      status,
      observation,
    };
    await UserStore.updateStatusSolicitation(solicitationUpdate);
    this.setState({
      page: pageList,
      isLoading: false,
      solicitations: await UserStore.getSolicitations()
    });
    history.push("/solicitation");
  }


  render() {
    const { UIStore, UserStore, history } = this.props;
    const {
      isLoading,
      solicitations,
      message,
      solicitation,
      page
    } = this.state;


    return <>
      {
        isLoading === true ? (
          <LoadingComponent />
        ) : page === pageList ? (
          <SolicitationComponent
            message={message}
            solicitations={solicitations}
            onDetail={async (data) => {
              await this.onDetail(data);
            }}
            onSearch={(id, filterValue) => {
              /*
                método para futura necessidade de uma pesquisa avançada por parte do back-end 
              */
            }}
          />
        ) : (
          <SolicitationDetailComponent
            solicitation={solicitation}
            setObservation={(value) => {
              this.setState({
                observation: value,
              });
            }}
            onGoBack={async () => {
              this.setState({
                page: pageList
              });
              history.push("/solicitation");
            }}
            onUpdateStatus={async (solicitationId, status) => {
              await this.onUpdateStatus(solicitationId, status);
            }}
          />
        )
      }
      {showChat()}
    </>
  }
}

export default SolicitationContainer;
