/* eslint-disable import/prefer-default-export */
import React from 'react';
import styled from "styled-components";
import { mask as masker, unMask } from "remask";

import { Input as MInput, } from 'reactstrap';

export const Input = styled(MInput)`
  /* outline: none;
  margin-bottom: 20px;
  padding: 10px 18px;

  font-size: 0.75rem;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;

  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  line-height: 1.42857;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.4285rem;
  box-shadow: none;
  background-color: #262a3c; */
  /* color: ${({ theme }) => theme.contrastTextPrimary}; */

  /* &:focus {
    border-color: ${({ theme }) => theme.tertiary};
  } */
`;

export const TextInputMask = ({ mask, onChange, ...props }) => {
  const handleChange = (ev) => {
      const originalValue = unMask(ev.target.value);
      const maskedValue = masker(originalValue, mask);
      onChange({target: { name: ev.target.name, value: maskedValue }});
  };

  return <Input {...props} onChange={handleChange} />;
};
