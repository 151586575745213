export const goBoxThemes = {
  darkTheme: {
    background: "#1E1E1E",
    backgroundDark: "#0F131A",
    backgroundDarkMediun: "#323233",

    fieldColor: "#535365",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#216177",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#323233",
    backgroundDarkMediun: "#234C6A",

    fieldColor: "#535365",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#0085B0",
    primaryDisabled: "#234C6A",
    secondary: "#216177",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const bbankThemes = {
  darkTheme: {
    background: "#1E1E1E",
    backgroundDark: "#40123C",
    backgroundDarkMediun: "#782273",

    fieldColor: "#1A7571",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#842F82",
    primaryDisabled: "#234C6A",
    secondary: "#26ADA6",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#40123C",
    backgroundDarkMediun: "#782273",

    fieldColor: "#1A7571",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#842F82",
    primaryDisabled: "#234C6A",
    secondary: "#26ADA6",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const pagueakiThemes = {
  darkTheme: {
    background: "#183A1B",
    backgroundDark: "#090C0A",
    backgroundDarkMediun: "#1A231F",

    fieldColor: "#4D6B24",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#0DA00E",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#183A1B",
    backgroundDarkMediun: "#197D1C",

    fieldColor: "#305925",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#0DA00E",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const genialThemes = {
  darkTheme: {
    background: "#1B1F27",
    backgroundDark: "#1B1F27",
    backgroundDarkMediun: "#B23606",

    fieldColor: "#8A2B05",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#FC9C33",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#1B1F27",
    backgroundDarkMediun: "#B23606",

    fieldColor: "#8A2B05",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#FC9C33",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const coopermobilThemes = {
  darkTheme: {
    background: "#1E1E1E",
    backgroundDark: "#1B1F27",
    backgroundDarkMediun: "#111318",

    fieldColor: "#111318",
    fieldContrastColor: "#fff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#E74C32",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#1B1F27",
    backgroundDarkMediun: "#111318",

    fieldColor: "#111318",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#E74C32",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const triacomThemes = {
  darkTheme: {
    background: "#2A3A51",
    backgroundDark: "#0F131A",
    backgroundDarkMediun: "#27293D",

    fieldColor: "#535365",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#B62833",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#353646",
    backgroundDarkMediun: "#535365",

    fieldColor: "#535365",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#3684BA",
    primaryDisabled: "#234C6A",
    secondary: "#B62833",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const faciegThemes = {
  darkTheme: {
    background: "#183A1B",
    backgroundDark: "#090C0A",
    backgroundDarkMediun: "#1A231F",

    fieldColor: "#4D6B24",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    primary: "#00A864",
    primaryDisabled: "#006A40",
    secondary: "#0DA00E",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    background: "#FFFFFF",
    backgroundDark: "#183A1B",
    backgroundDarkMediun: "#00A864",

    fieldColor: "#305925",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    primary: "#00A864",
    primaryDisabled: "#006A40",
    secondary: "#0DA00E",
    tertiary: "#27293D",

    contrastTextPrimary: "#FFFFFF",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};

export const saudesaThemes = {
  darkTheme: {
    primary: "#00ACCC",
    primaryDisabled: "#005666",
    secondary: "#16EAB2",
    tertiary: "#27293D",

    background: "#0F131A",
    backgroundDark: "#002B33",
    backgroundDarkMediun: "#005666",

    fieldColor: "#002B33",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    contrastTextPrimary: "#000000",
    textSecondary: "#000000",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    primary: "#00ACCC",
    primaryDisabled: "#005666",
    secondary: "#16EAB2",
    tertiary: "#27293D",

    background: "#FFFFFF",
    backgroundDark: "#002B33",
    backgroundDarkMediun: "#008199",

    fieldColor: "#002B33",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    contrastTextPrimary: "#000000",
    textSecondary: "#000000",
    textTertiary: "#FFFFFF",
  }
};

export const bsvThemes = {
  darkTheme: {
    primary: "#5FAB98",
    primaryDisabled: "#005666",
    secondary: "#91c740",
    tertiary: "#27293D",

    background: "#0F131A",
    backgroundDark: "#002B33",
    backgroundDarkMediun: "#34786B",

    fieldColor: "#002B33",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#ffffff",
    contrastBackgroundDark: "#ffffff",
    contrastBackgroundDarkMediun: "#ffffff",

    contrastTextPrimary: "#000000",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  },

  lightTheme: {
    primary: "#5FAB98",
    primaryDisabled: "#005666",
    secondary: "#91c740",
    tertiary: "#27293D",

    background: "#FFFFFF",
    backgroundDark: "#0F131A",
    backgroundDarkMediun: "#48A490",

    fieldColor: "#002B33",
    fieldContrastColor: "#ffffff",

    contrastBackground: "#000",
    contrastBackgroundDark: "#FFFFFF",
    contrastBackgroundDarkMediun: "#FFFFFF",

    contrastTextPrimary: "#000000",
    textSecondary: "#FFFFFF",
    textTertiary: "#FFFFFF",
  }
};