import React, { Component } from 'react';
import styled from 'styled-components';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { MarkerPlaceGetAllCategory } from '../../../services/MarketplaceService';


/*

{
    "categoryId": 1,
    "description": "Estoque inicial"
},

*/

const MyDiv = styled.div`
    transition-duration: 500ms;
    background: ${({ isOpen }) => isOpen ? "#E0E0E0" : "transparent"};
    border-radius: 5px;
    padding: ${({ isOpen }) => isOpen ? 15 : 0}px;
    padding-top: 0;
    padding-bottom: 0;
    
    .content {
        margin: 15px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
    }
`

class MarkeplaceCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: [],
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const list = await MarkerPlaceGetAllCategory();
        this.setState({
            category: list
        });
    }


    render() {
        const { category } = this.state;
        const { categoryId, onChange, disabled } = this.props;
        return (<MyDiv>
            <FormGroup row>
                <Row>
                    <Label
                        for="exampleSelect"
                        sm={2}
                    >
                        Categoria
                    </Label>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            value={`${categoryId}`}
                            disabled={disabled}
                            onChange={(event) => {
                                onChange(event.target.value);
                            }}
                        >
                            <option value="0">
                                Selecione uma categoria
                            </option>)
                            {
                                category.map((iten, index) => <option value={iten.categoryId}>
                                    {iten.description}
                                </option>)
                            }
                        </Input>
                    </Col>
                </Row>
            </FormGroup>
        </MyDiv>);
    }
}

export default MarkeplaceCategory;