import React from "react";
import { inject, observer } from "mobx-react";
import { Button, CardBody, Form, FormGroup, Row, Col } from "reactstrap";
import { Section, Section2, Section3 } from "./styles";

import { Card, CardHeader } from "../../atoms/Card";
import { Input } from "../../atoms/Input";
import Logo from "../../../icons/logos/Logo";
import { showChat } from "../../../utils/Util";


@inject("UIStore")
@observer
class LoginComponent extends React.Component {
  render() {
    const { UIStore } = this.props;
    return (
      <>
        {
          UIStore.width > 768 ?
            <Section isFlex>
              <Row style={{ width: "100%" }}>
                <Col sm="12" md="8">
                  <Section2>
                    <Logo />
                  </Section2>
                </Col>
                <Col sm="12" md="4">
                  <Section2>
                    <MyForm {...this.props} />
                  </Section2>
                </Col>
              </Row>
            </Section> :
            <>
              <Section>
                <Section3 mheight={10} />
                <Section2 noHeight mWidth={UIStore.width <= 444 ? 80 : 70}>
                  <Logo />
                </Section2>
                <Section3 mheight={60}>
                  <MyForm {...this.props} />
                </Section3>
                <Section3 mheight={10} />
              </Section>
            </>
        }

        {showChat(true)}
      </>

    );
  }
}

const MyForm = ({ onChange, goLogin, password, document }) => (<Card>
  <CardHeader>
    <h6 className="title">Bem vindo</h6>
  </CardHeader>
  <CardBody>
    <Form>
      <Row>
        <Col>
          <FormGroup>
            <label>Documento</label>
            <Input
              id="document"
              name="document"
              value={document}
              onChange={(e) => onChange(e)}
              placeholder="CPF/CNPJ"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <label>Senha</label>
            <Input
              name="password"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => onChange(e)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button className="btn-fill" color="primary" onClick={goLogin}>
            Entrar
          </Button>
        </Col>
      </Row>
    </Form>
  </CardBody>
</Card>)

export default LoginComponent;
