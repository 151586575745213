/* eslint-disable  no-case-declarations */
import { inject, observer } from 'mobx-react';
import React from 'react';
import IconExpanded from '../../../icons/expanded/IconExpanded';
import { FixedPages } from '../../../utils/Constants';
import { isMobile } from '../../../utils/Util';
import { ExpandedMenu, SideMenuListItem } from './styles';

@inject("UIStore")
@observer
class SideMenuOption extends React.Component {

    constructor(props) {
        super(props);
        const { UIStore, item } = this.props;
        this.state = {
            expanded: UIStore.currentCategoryMenuSelected === `sideMenu${item.categoryId}`,
        }
    }

    onPress = () => {

        const { UIStore, item, history, setOpened } = this.props;
        const { expanded, selected } = this.state;
        if (item.menus == null || item.menus === undefined) {
            const base_url = window.location.origin;
            switch (item.type) {
                case 2:
                    window.open(item.content, "_blank")
                    break;

                case 3:
                    UIStore.changeCurrentMenuSelected(`sideMenu${item.menuId}`)
                    UIStore.changeCurrentCategoryMenuSelected(``)
                    if (setOpened !== undefined)
                        setOpened();
                    if (history.location.pathname.includes(FixedPages(item.key))) {
                        return;
                    }
                    window.location.href = (`${base_url}/${FixedPages(item.key)}`);
                    break;

                default:
                    if (UIStore !== undefined && item.content != null && item.content !== undefined) {
                        UIStore.setCurrentMarkdown(item.content);
                        UIStore.changeCurrentCategoryMenuSelected(``)
                        UIStore.changeCurrentMenuSelected(`sideMenu${item.menuId}`)
                        if (setOpened !== undefined)
                            setOpened();
                        if (history.location.pathname !== "home") {
                            window.location.href = (`${base_url}/home`);
                        }
                    }
                    break;
            }
            if (isMobile()) {
                UIStore.closeLeftNavBar();
                UIStore.closeTopNavBar();
            }
        }
        else {
            this.setState({ expanded: !expanded })
        }

    }

    render() {
        const { UIStore, index, item, history, isLateral, isHorizontal, subItem } = this.props;
        const { expanded } = this.state;
        const hasMenus = item.menus != null && item.menus !== undefined && (isHorizontal ? item.menus.horizontalMenus : item.menus.verticalMenus).length > 0;
        const AltItem = item;
        return (
            (
                !hasMenus &&
                (item.categoryId != null && item.categoryId !== undefined) &&
                !(
                    (item.content != null && item.content !== undefined && item.content !== "") ||
                    (item.key != null && item.key !== undefined && item.key !== "")
                )
            ) ?
                <> { console.log(item) } </> :
                <>
                    <SideMenuListItem
                        subItem={subItem}
                        index={index}
                        className={`SideMenuListItem ${UIStore.currentMenuSelected === `sideMenu${item.menuId}` ? "selected" : ""}`}
                        expanded={expanded}
                        onMouseLeave={!isHorizontal || UIStore.width < 450 ? undefined : () => this.setState({ expanded: false })}
                        isHorizontal={isHorizontal}
                        selected={UIStore.currentMenuSelected === `sideMenu${item.menuId}`}>
                        <div
                            style={{ direction: "ltr", textAlign: "start" }}
                            onClick={this.onPress}
                            onKeyDown={this.onPress}
                            role="button"
                            tabIndex={0}
                        >

                            {item.title}
                            {
                                hasMenus ?
                                    <IconExpanded style={{
                                        float: "right",
                                        marginTop: isHorizontal ? "10%" : "5%",
                                        marginLeft: isHorizontal ? "10px" : "0px"
                                    }} expanded={expanded} /> : <></>
                            }

                        </div>

                        {
                            hasMenus ?
                                <ExpandedMenu
                                    isLateral={isLateral}
                                    expanded={expanded}
                                    itemsCount={(
                                        isHorizontal ?
                                            item.menus.horizontalMenus :
                                            item.menus.verticalMenus
                                    ).length}>
                                    <ul>
                                        {
                                            (
                                                isHorizontal ?
                                                    item.menus.horizontalMenus :
                                                    item.menus.verticalMenus
                                            ).map((i, mIndex) =><>
                                            
                                            <SideMenuOption subItem index={mIndex} UIStore={UIStore} item={i} history={history} setOpened={() => {
                                                if (isLateral)
                                                    UIStore.changeCurrentCategoryMenuSelected(`sideMenu${AltItem.categoryId}`)
                                            }} />

                                            </>)
                                        }
                                    </ul>
                                </ExpandedMenu> : <></>
                        }

                    </SideMenuListItem >
                </>
        );
    }
}

export default SideMenuOption;