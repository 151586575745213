import qs from "querystring";
import api, { tokenlessApi } from "./api";
import { AUTH_DATA, URL_AUTH_BASE_PATH, URL_PERSON_BASE_PATH } from "../utils/Constants";

export class AuthService {
  login = async (document, password) => {
    const requestBody = {
      ...AUTH_DATA,
      username: document,
      password,
    };

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const url = `${URL_AUTH_BASE_PATH}/connect/token`;
    try {
      const { data } = await api.post(url, qs.stringify(requestBody), config);
      return data;
    } catch (err) {
      return null;
    }
  };

  getMenus = async (personId, token) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`,
      },
    };
    const url = `${URL_PERSON_BASE_PATH}/menus/${personId}/dashboard`;
    try {
      const { data } = await api.get(url, config);
      return data;
    } catch (err) {
      return null;
    }
  };

  getMenuscategory = async (personId, token) => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${token}`,
      },
    };

    const url = `${URL_PERSON_BASE_PATH}/menuscategory/${personId}/dashboard`;
    try {
      const { data } = await api.get(url, config);
      return data;
    } catch (err) {
      return null;
    }
  };

  loginRegister = async (renew) => {
    const accessToken = localStorage.getItem("loginRegister_accessToken")
    if (renew === true || accessToken == null || accessToken === undefined || accessToken === "undefined") {
      const requestBody = {
        client_id: "TriacomService", // This is the client_id
        client_secret: "TriacomSecretService0101", // This is the client_secret
        grant_type: "password",
        scope: "triacomservice",
        username: "accountbff",
        password: "triacomserviceapi2020",
      };

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const url = `${URL_AUTH_BASE_PATH}/connect/token`;
      try {
        const { data } = await tokenlessApi.post(url, qs.stringify(requestBody), config);
        if (data != null && data !== undefined) {
          localStorage.setItem("loginRegister_accessToken", data.accessToken)
        }
        return data;
      } catch (err) {
        return null;
      }
    } else {
      try {
        return { accessToken };
      } catch (e) {
        return null;
      }
    }

  };
}

const authService = new AuthService();
export default authService;
