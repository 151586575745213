import { inject, observer } from 'mobx-react';
import React from 'react';

import logo from "./logo.png"

@inject("UIStore")
@observer
class LogoCoopermobil extends React.Component {
    render() {
        const { UIStore } = this.props;
        // return (<img src={UIStore.isDarkTheme ? logo : logodark} alt="logo"/>);
        return (<img src={logo} alt="logo"/>);
    }
}

export default LogoCoopermobil;