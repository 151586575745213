import { inject, observer } from 'mobx-react';
import React from 'react';
import IconPointMenu from '../../../icons/IconPointMenu/IconPointMenu';
import IconLogout from '../../../icons/logout/IconLogout';

import IconMenu from '../../../icons/menu/IconMenu';
import IconTheme from '../../../icons/theme/IconTheme';
import SideMenuOption from './SideMenuOption';
import { CollapsedTopMenu, ExpandedTopMenu, SideMenuList, TopMenu, TopMenuButton, TopMenuList } from './styles';

@inject("UserStore", "UIStore")
@observer
class TopNavMenu extends React.Component {

    render() {
        const { UserStore, UIStore, history } = this.props;
        const breakingPoint = 700;
        return (<>
            <TopMenu>
                <div className="TopMenuGrid">
                    <div>
                        <TopMenuButton
                            disabled={UIStore.topNavBarIsOpen}
                            type='button'
                            onClick={() => {
                                if (UIStore.leftNavBarIsOpen === true)
                                    UIStore.closeLeftNavBar();
                                else
                                    UIStore.openLeftNavBar();
                            }}>
                            <IconMenu />
                        </TopMenuButton>
                    </div>

                    {
                        UIStore.width > breakingPoint ?
                            <TopMenuList isHorizontal>
                                {

                                    (UserStore.menus != null &&
                                        UserStore.menus !== undefined ?
                                        UserStore.menus.horizontalMenus :
                                        [])
                                        .map((item) => <SideMenuOption isHorizontal item={item} history={history} />)
                                }
                            </TopMenuList> : <></>
                    }

                    <div className="justify-end">
                        <TopMenuButton
                            disabled={UIStore.width < breakingPoint}
                            type='button'
                            onClick={() => {
                                UIStore.changeThemesMode(!UIStore.isDarkTheme);
                            }}>
                            <IconTheme />
                        </TopMenuButton>

                        <TopMenuButton
                            disabled={UIStore.width < breakingPoint}
                            type='button'
                            onClick={() => {
                                UserStore.logout();
                                window.location.replace("/");
                            }}>
                            <IconLogout />
                        </TopMenuButton>
                    </div>
                    {
                        UIStore.width < breakingPoint
                            ? <div style={{ float: "right", }}>
                                <CollapsedTopMenu>
                                    <TopMenuButton type='button' onClick={() => {
                                        if (UIStore.topNavBarIsOpen === true)
                                            UIStore.closeTopNavBar();
                                        else
                                            UIStore.openTopNavBar();
                                    }}>
                                        <IconPointMenu />
                                    </TopMenuButton>
                                </CollapsedTopMenu>

                            </div> : <></>
                    }
                </div>

                <ExpandedTopMenu isOpen={UIStore.topNavBarIsOpen}>
                    <SideMenuList>
                        {
                            (UserStore.menus != null &&
                                UserStore.menus !== undefined ?
                                UserStore.menus.horizontalMenus :
                                [])
                                .map((item) => <SideMenuOption isHorizontal item={item} history={history} />)
                        }
                        <li>
                            <TopMenuButton
                                type='button'
                                style={{
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: "20px auto",
                                    justifyItems: "start"
                                }}
                                onClick={() => {
                                    UIStore.changeThemesMode(!UIStore.isDarkTheme);
                                }}>
                                <IconTheme />
                                <div className="TopMenuButtonText" style={{ paddingLeft: "20px" }}>
                                    Tema {UIStore.isDarkTheme ? "claro" : "escuro"}
                                </div>
                            </TopMenuButton>
                        </li>
                        <li>
                            <TopMenuButton
                                type='button'
                                style={{
                                    width: "100%",
                                    display: "grid",
                                    gridTemplateColumns: "20px auto",
                                    justifyItems: "start"
                                }}
                                onClick={() => {
                                    UserStore.logout();
                                    window.location.replace("/");
                                }}>
                                <IconLogout />
                                <div className="TopMenuButtonText" style={{ paddingLeft: "20px" }}>
                                    Sair
                                </div>
                            </TopMenuButton>
                        </li>
                    </SideMenuList>
                </ExpandedTopMenu>
            </TopMenu>
        </>);
    }
}
export default TopNavMenu;