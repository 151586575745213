import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import add from "../../../assets/icons/add.png";
import { MarkerPlaceGetAllLocals, MarkerPlaceGetManintainLocal } from '../../../services/MarketplaceService';
import AlertComponent from '../../atoms/AlertComponent';


/*

{
    "localId": 1,
    "personId": 2,
    "name": "Triacom Tecnologia",
    "address": "Av. Rep. Argentina, 1336 - Sala 905 - Vila Izabel, Curitiba - PR, 80620-010"
},
    
*/

const MyDiv = styled.div`
    transition-duration: 500ms;
    background: ${({ isOpen }) => isOpen ? "#E0E0E0" : "transparent"};
    border-radius: 5px;
    padding: ${({ isOpen }) => isOpen ? 15 : 0}px;
    padding-top: 0;
    padding-bottom: 0;
    
    .content {
        margin: 15px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 0;
    }
`

class MarkeplaceLocals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            locals: [],
            isOpen: false,
            name: "",
            address: ""
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const list = await MarkerPlaceGetAllLocals();
        this.setState({
            name: "",
            address: "",
            isOpen: false,
            locals: list
        });
    }


    render() {
        const { locals, isOpen, name, address } = this.state;
        const { localId, onChange, disabled } = this.props;
        return (<MyDiv isOpen={isOpen}>
            <FormGroup row>
                <Row>
                    <Label
                        for="exampleSelect"
                        sm={2}
                    >
                        Local
                    </Label>
                </Row>
                <Row>
                    <Col sm={10}>
                        <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            value={`${localId}`}
                            disabled={disabled}
                            onChange={(event) => {
                                this.setState({ isOpen: false, name: "" })
                                onChange(event.target.value);
                            }}
                        >
                            <option value="0">
                                Selecione um local
                            </option>
                            {
                                locals.map((iten, index) => <option value={`${iten.localId}`}>
                                    {`${iten.name} - ${iten.address}`}
                                </option>)
                            }
                        </Input>
                    </Col>
                    <Col sm={2} style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    isOpen: !isOpen,
                                    name: "",
                                    address: ""
                                })
                            }}
                            style={{ "width": "40px", "height": "40px", padding: "0px" }}
                            disabled={disabled}
                        >
                            <img alt="Adicionar" src={add} style={{ "height": "20px", "width": "20px" }} />
                        </Button>
                    </Col>
                </Row>

                {
                    isOpen && <Row className="content">
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <label>Nome do novo local</label>
                                    <Input
                                        name="name"
                                        placeholder="Nome"
                                        value={name}
                                        onChange={(event) => {
                                            this.setState({ name: event.target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <FormGroup>
                                    <label>Endereço</label>
                                    <Input
                                        name="address"
                                        placeholder="Nome"
                                        value={address}
                                        onChange={(event) => {
                                            this.setState({ address: event.target.value })
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Button
                                color="primary"
                                style={{ marginBottom: "20px" }}
                                onClick={async () => {
                                    const response = await MarkerPlaceGetManintainLocal({
                                        name,
                                        address
                                    },
                                        (err) => {
                                            AlertComponent.error(err);
                                        });
                                    if (response != null && response !== undefined) {
                                        this.load();
                                    }
                                }}>
                                Salvar
                            </Button>
                        </Row>

                    </Row>
                }
            </FormGroup>
        </MyDiv>);
    }
}

export default MarkeplaceLocals;