import React, { Component } from 'react';
import CurrencyInput from 'react-currency-masked-input';
import { Alert, Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { formatCentsToRealStr } from '../../../../utils/Monetary';
import MarkeplaceGroupProduct from '../../../molecules/marketplace/MarkeplaceGroupProduct';
import MovementContainer from './Movements/MovementsContainer';


/*

{
    "productId": 21,
    "groupId": 1,
    "description": "Teste 2",
    "name": "Teste",
    "code": "Teste",
    "value": 100,
    "owner": 2
}

*/

class ProductMaintain extends Component {
    constructor(props) {
        super(props);
        const { productSelected } = props;
        if (
            productSelected != null &&
            productSelected !== undefined &&
            productSelected.productId !== undefined &&
            productSelected.productId > 0
        )
            this.state = {
                name: productSelected.name,
                description: productSelected.description,
                groupId: productSelected.groupId,
                code: productSelected.code,
                value: productSelected.value,
                newProduct: false
            }
        else
            this.state = {
                name: "",
                description: "",
                groupId: 0,
                code: "",
                value: "0",
                newProduct: true
            }
    }

    checProps = (nextProps) => {
        const { productSelected } = nextProps;
        const {
            name,
            description,
            groupId,
            code,
            value,
            newProduct,
            productId,
        } = this.state;

        if (
            productSelected != null &&
            productSelected !== undefined &&
            productSelected.productId !== undefined &&
            productSelected.productId > 0 &&
            (
                productId !== productSelected.productId || (
                    name === "" &&
                    description === "" &&
                    groupId === 0 &&
                    code === "" &&
                    value === "0" &&
                    newProduct === true
                )
            )
        )
            this.setState({
                productId: productSelected.productId,
                name: productSelected.name,
                description: productSelected.description,
                groupId: productSelected.groupId,
                code: productSelected.code,
                value: `${productSelected.value}`,
                newProduct: false
            })
        else if (
            (
                productSelected == null ||
                productSelected === undefined || 
                productSelected.productId == null ||
                productSelected.productId === undefined
            ) &&
            !newProduct
        )
            this.setState({
                name: "",
                description: "",
                groupId: 0,
                code: "",
                value: "0",
                newProduct: true
            })

    }

    mRegister = () => {
        const { register } = this.props;

        const {
            productId,
            groupId,
            description,
            name,
            code,
            value,
            owner
        } = this.state;

        register({
            productId,
            groupId: parseInt(groupId, 10),
            description,
            name,
            code,
            value: parseInt(value.replace(/[^0-9]/g, ""), 10),
            owner,
        })
    }

    form1 = () => {
        const {
            name,
            description,
            groupId,
            code,
            value,
        } = this.state;
        const strValue = `${value}`.replace(/[^0-9]/g, "")
        const { isOpen, closeModal, isLoadingSave } = this.props;
        return (<>
            <FormGroup>
                <label>Nome</label>
                <Input
                    disabled={isLoadingSave}
                    name="name"
                    placeholder="Nome"
                    value={name}
                    onChange={(event) => {
                        this.setState({ name: event.target.value })
                    }}
                />
            </FormGroup>

            <FormGroup>
                <label>Descrição</label>
                <Input
                    disabled={isLoadingSave}
                    name="description"
                    type="textarea"
                    placeholder="Descrição"
                    value={description}
                    rows="6"
                    onChange={(event) => {
                        this.setState({ description: event.target.value })
                    }}
                />
            </FormGroup>

            <MarkeplaceGroupProduct
                disabled={isLoadingSave}
                groupId={groupId}
                onChange={(id) => {
                    this.setState({
                        groupId: id
                    })
                }} />

            <FormGroup>
                <label>Código de barras</label>
                <Input
                    disabled={isLoadingSave}
                    name="code"
                    placeholder="Código de barras"
                    value={code}
                    onChange={(event) => {
                        this.setState({ code: event.target.value })
                    }}
                />
            </FormGroup>

            <FormGroup>
                <label>Valor</label>
                <CurrencyInput
                    disabled={isLoadingSave}
                    className="form-control"
                    placeholder="0,00"
                    name="value"
                    separator=","
                    type="text"
                    defaultValue={formatCentsToRealStr(value)}
                    onChange={(event) => {
                        this.setState({ value: event.target.value })
                    }}
                />
            </FormGroup>

            <Row>
                <Col style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                    <Button color="primary" onClick={this.mRegister} disabled={isLoadingSave}>
                        Salvar
                    </Button>
                    <Button
                        style={{ marginRight: "10px" }}
                        onClick={closeModal} disabled={isLoadingSave}>
                        Cancelar
                    </Button>
                </Col>
            </Row>
        </>)
    }

    render() {
        this.checProps(this.props)
        const { isOpen, closeModal, isLoadingSave, productSelected } = this.props;
        const { productId } = productSelected;
        const {
            newProduct,
            name,
        } = this.state;
        return (<>
            <Modal
                size={newProduct ? "md" : "xl"}
                isOpen={isOpen}
                style={{ color: "#000" }}
            >
                <ModalBody>
                    <Row>
                        <Col sm={11}>
                            <h3>
                                {
                                    newProduct ?
                                        "Novo Produto" : name
                                }
                            </h3>
                        </Col>
                        <Col sm={1} style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                            <Button onClick={closeModal} disabled={isLoadingSave}>
                                X
                            </Button>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm={newProduct ? 12 : 5}>{this.form1()}</Col>
                        {
                            !newProduct &&
                            <Col sm={7}>
                                <MovementContainer productId={productId} />
                            </Col>
                        }
                    </Row>
                    {
                        isLoadingSave &&
                        <Row>
                            <Col>
                                <Alert color="primary">
                                    Salvando produto
                                </Alert>
                            </Col>
                        </Row>
                    }
                </ModalBody>
            </Modal>
        </>);
    }


}



export default ProductMaintain;