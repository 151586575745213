/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { inject, observer } from "mobx-react";
import ReactMarkdown from "react-markdown";
import Dasboard from "../../molecules/Dashboard/Dashboard";
import { showChat } from "../../../utils/Util";


@inject("UIStore")
@observer
class HomeComponent extends React.Component {

  render() {
    const { UIStore, currentMarkdown, history, onMouseEnter } = this.props;
    const theme = UIStore.isDarkTheme ? "theme=night" : "theme=day";
    const mark = currentMarkdown == null || currentMarkdown === undefined ?
      "" : currentMarkdown.replace("theme=night", theme).replace("theme=day", theme);
    return (
      <>
        <ReactMarkdown unwrapDisallowed source={mark} escapeHtml={false} />
        {showChat()}
      </>
    )
  }
}

export default HomeComponent;
