import React from 'react';
import { inject, observer } from 'mobx-react';
import styled from "styled-components";

const MyDiv = styled.div`
    width: 100%;
    height: 100%;
    transition-duration: 500ms;
    svg{
        width: 100%;
        height: 100%;
    }
`;

@inject("UIStore")
@observer
class IconPointMenu extends React.Component {
    render() {
        const { UIStore } = this.props;
        return <MyDiv>
            <svg version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt"
                height="512.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill={UIStore.themes[UIStore.isDarkTheme ? "darkTheme" : "lightTheme"].contrastBackgroundDarkMediun}
                    stroke="none">
                    <path d="M2420 5104 c-234 -50 -436 -254 -485 -489 -95 -454 306 -855 760
-760 238 50 440 252 490 490 95 456 -309 857 -765 759z"/>
                    <path d="M2420 3184 c-234 -50 -436 -254 -485 -489 -95 -454 306 -855 760
-760 238 50 440 252 490 490 95 456 -309 857 -765 759z"/>
                    <path d="M2420 1264 c-234 -50 -436 -254 -485 -489 -95 -454 306 -855 760
-760 238 50 440 252 490 490 95 456 -309 857 -765 759z"/>
                </g>
            </svg>
        </MyDiv>
    }
}

export default IconPointMenu;