/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AlertComponent from './ui/atoms/AlertComponent';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

function receiveMessage(e)
{
	try{
    if(e.data.includes("copyToClipBoard:")){
      navigator.clipboard.writeText(e.data.replace("copyToClipBoard:", "")).then(
        () => AlertComponent.sucess("Link Copiado"),
        () => {
          AlertComponent.error("Link não Copiado");
        }
      );
    }
  } catch(_){}	
}

window.addEventListener("message", receiveMessage, false);