import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Table } from '../../../molecules/Table/Table';
import { ContentTable } from '../../../theme/Global';

import add from "../../../../assets/icons/add.png";
import { DefaultColumnFilter } from '../../../molecules/Table/Filters';
import { formatBr } from '../../../../utils/Date';
import { formatCentsToRealStr } from '../../../../utils/Monetary';

const ProductList = ({ products, onMaintain }) => (<>
    <ContentTable>
        <Row>
            <Col>
                <h1>Produtos</h1>
            </Col>

            <Col style={{ "display": "flex", "flex-direction": "row-reverse" }}>
                <Button color="primary" onClick={()=>onMaintain(undefined)} style={{ "width": "46px", "height": "46px" }}>
                    <img alt="Adicionar" src={add} style={{ "height": "20px", "width": "20px" }} />
                </Button>
            </Col>
        </Row>
        <Row>
            <Col>
                <ContentTable>
                    <Table columns={React.useMemo(
                        () => [
                            { Header: 'Nome', accessor: 'name', minWidth: 81, Filter: DefaultColumnFilter },
                            {
                                Header: 'Valor',
                                accessor: 'value',
                                minWidth: 81,
                                filter: 'hardText',
                                Filter: DefaultColumnFilter,
                                myCustomRender: (value) => `R$ ${formatCentsToRealStr(value)}`,
                                customCompare: (originalObject, value) => {
                                    const finalvalue = value.toUpperCase()
                                        .replaceAll("R$", "")
                                        .replaceAll("RS", "")
                                        .replaceAll(" ", "");
                                    return `${formatCentsToRealStr(originalObject.value, false, true)}`.includes(finalvalue)
                                }
                            },
                            {
                                Header: 'Data de registro',
                                accessor: 'register',
                                Filter: DefaultColumnFilter,
                                filter: 'hardText',
                                myCustomRender: (value) => formatBr(value, false, true),
                                customCompare: (originalObject, value) => formatBr(originalObject.register, false, true).includes(value)
                            },
                        ],
                        []
                    )}
                        data={products}
                        onLinePressed={(index) => {
                            onMaintain(index)
                        }} />
                </ContentTable>
            </Col>
        </Row>
    </ContentTable>
</>);

export default ProductList;

// const s = {
//     "productId": 28,
//     "description": "Batata palha tradicional Elma Chips - 110g",
//     "group": null,
//     "groupId": null,
//     "register": "2021-11-17T12:57:37.091321",
//     "value": 699,
//     "quantity": 0,
//     "taxPlan": null,
//     "resultPlan": null,
//     "interest": null,
//     "iconUrl": null,
//     "paymentFrequencies": null,
//     "paymentFrequencyMessage": null,
//     "paymentWayMessage": null,
//     "paymentValueMessage": null,
//     "owner": 2,
//     "code": "7892840818319",
//     "name": "Bata Palha Tradicional",
//     "error": null
// };