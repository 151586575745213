/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const Section = styled.div`
  display:  ${({ isFlex }) => isFlex ? "flex" : "block"};
  align-items: center;
  /* justify-content: center; */
  ${({ noHeight }) => noHeight ? "" : "height: 100vh;"}
  width:  ${({ mWidth }) => mWidth !== undefined ? mWidth : 100}%;
`;

export const Section2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ noHeight }) => noHeight ? "" : "height: 100vh;"}
  
  img{
    max-width:  ${({ mWidth }) => mWidth !== undefined ? mWidth : 100}% !important;
  }
`;

export const Section3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ mheight }) => mheight}%;
  width: 100%;
`;
