import React from "react";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Step1Document from "./Step1Document";

import { Content } from "../../../theme/Global";
import Step2Info from "./Step2Info";

class AddPersonContainer extends React.Component {

    render() {
        const { onValidateDocument } = this.props
        return <>
            <Content>
                <AliceCarousel
                    fadeOutAnimation={true}
                    mouseDragEnabled={false}
                    touchTracking={false}
                    autoPlayDirection="rtl"
                    onSlideChanged={(_) => this.setState({})}
                    autoPlayActionDisabled
                    disableButtonsControls
                    disableDotsControls
                    ref={(el) => {
                        this.Carousel = el
                    }}
                >
                    <Step1Document
                        {...this.props}
                        onValidateDocumentInterceptor={() => {
                            onValidateDocument(({ isValidDocument }) => {
                                if (isValidDocument)
                                    this.Carousel.slideNext();
                            });
                        }}
                    />

                    <Step2Info
                        {...this.props}
                        onBackSlide={() => {
                            this.Carousel.slidePrev();
                        }}
                    />
                </AliceCarousel>
            </Content>
        </>
    }
}

export default AddPersonContainer;