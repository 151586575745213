import React from 'react';
import CurrencyInput from "react-currency-masked-input";
import { Input } from 'reactstrap';
import { formatReal } from '../../../utils/Monetary';
import { formatSolicitationStatus } from '../../../utils/Util';


export const DefaultColumnFilter = ({
    column
}) => {
    const { filterValue, setFilter } = column
    return (
        <Input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value || undefined)}
            placeholder={column.Header}
        />
    );
}

export const MonetaryFilter = ({
    column
}) => {
    const { filterValue, setFilter } = column
    return (
        <CurrencyInput
            className="form-control"
            name="value"
            separator=","
            type="text"
            placeholder={column.Header}
            value={formatReal(filterValue) || ''}
            onChange={(e) => {
                const tmp = parseInt(e.target.value.replace(/[^0-9]/g, ""), 10)
                setFilter(tmp || undefined)
            }}

        />
    );
}




export const SelectFilter = ({
    column
}) => {
    const { filterValue, setFilter, preFilteredRows, id } = column;
    const myOptions = React.useMemo(() => {
        const options = [];
        preFilteredRows.forEach(row => {
            if (!options.includes(row.values[id]))
                options.push(row.values[id])
        })
        return options;
    }, [id, preFilteredRows])
    return (
        <Input
            type="select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Todos</option>
            {myOptions.map((option) => (
                <option value={option}>
                    {formatSolicitationStatus(option)}
                </option>
            ))}
        </Input>
    )
}

export const SelectFilter2 = ({
    column
}) => {
    const { filterValue, setFilter, preFilteredRows, id } = column;
    const myOptions = React.useMemo(() => {
        const options = [];
        preFilteredRows.forEach(row => {
            if (!options.includes(row.values[id]))
                options.push(row.values[id])
        })
        return options;
    }, [id, preFilteredRows])
    return (
        <Input
            type="select"
            value={filterValue}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">Todos</option>
            {myOptions.map((option) => (
                <option value={option}>
                    {(() => {
                        switch (option) {
                            case 0:
                                return "Erro";
                            case 1:
                                return "Criado";
                            default:
                                return "";
                        }
                    })()}
                </option>
            ))}
        </Input>
    )
}