import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../services/Auth";


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        document.getElementById("octadeskCache").innerHTML = "";
        const elem = document.getElementsByClassName("octadesk-octachat-app");
        if (elem.length > 0)
          elem[0].remove(elem);
  
  
        return auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }}
    />
  );
  