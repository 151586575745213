/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const MainContent = styled.div`
  width: 100%;
  overflow-y: auto;
`

export const Badge = styled.div`
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: #0F131A;
    border-radius: 10px;
`;

export const CheckBoxContent = styled.div`
    padding: 5px;
`;

export const CustomSelector = styled.div`
  position: relative;
  max-width: 300px;
  margin: 0;
    padding: 0;
  button{
    border-bottom-right-radius: ${({ expanded }) => expanded ? `0px` : `5px`};
    border-bottom-left-radius: ${({ expanded }) => expanded ? `0px` : `5px`};
  }
`;

export const Header = styled.div`
    width: 100%;
    transition-duration: 500ms;
    height: ${({ expanded }) => expanded ? `200px` : `0px`};
    overflow: auto;
    position: absolute;
    z-index: 2;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.contrastBackground};
    border: ${({ expanded, theme }) => expanded ? ` 1px solid ${theme.contrastBackground}` : `none`};
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    label {
      width: 100%;
      &:hover{
        background-color: ${({ theme }) => theme.backgroundDarkMediun}7a !important;
        color:  ${({ theme }) => theme.contrastBackgroundDarkMediun};
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }

    .header {
        overflow: auto;
    }

    .content {
        overflow: auto;
        height: ${({ expanded }) => expanded ? `min-content` : `0px`};
        opacity: ${({ expanded }) => expanded ? 1 : 0};
        ${({ expanded }) => expanded ? `` : `margin: 0px !important; padding: 0px !important;`}
    }

    .header {
      overflow: hidden;
      margin: ${({ expanded }) => expanded ? `0.5rem` : `0px`};
      padding: ${({ expanded }) => expanded ? `0.5rem` : `0px`};
    }
`

export const TableFilter = styled.div`
  div {
    overflow: hidden;
    height: ${({ expanded }) => expanded ? `min-content` : `0px`};
    opacity: ${({ expanded }) => expanded ? 1 : 0};
    transition-duration: 500ms;
  }

  div input, select {
    margin-bottom:  ${({ expanded }) => expanded ? 20 : 0}px;
    transition-duration: 500ms;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`

export const ExpandedButton = styled.button`
  outline: none;
  width: min-content;
  border: none;
  background-color: ${({ theme }) => theme.fieldColor};
  color: ${({ theme }) => theme.fieldContrastColor};
  border-radius: 5px;
  padding: 10px;
`

export const InnerPagination = styled.div`
  padding: 10px !important;
`

export const NavigationOptions = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  .NavigationOptionsContent{
    float: right;
    margin-right: 10px;
    margin-left: 10px;
  }
`