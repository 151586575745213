import { Button, Col, Row, UncontrolledCollapse, ListGroupItem, ListGroup, Container } from "reactstrap";
import React from "react";
import { ContentTable } from "../../theme/Global";
import { formatBr } from "../../../utils/Date";
import { documentFormater } from "../../../utils/Util";
import LoadingComponent from "../../atoms/LoadingComponent/LoadingComponent"

import add from "../../../assets/icons/add.png";
import { Table } from "../../molecules/Table/Table";
import { DefaultColumnFilter } from "../../molecules/Table/Filters";


const ListPersonComponent = ({
  people,
  onAdd,
  isLoading,
}) => <>
    {
      isLoading ? <LoadingComponent /> : <>
        <ContentTable>
          <Row>
            <Col>
              <h1>Contas</h1>
            </Col>

            <Col style={{ "display": "flex", "flex-direction": "row-reverse" }}>
              <Button color="primary" onClick={onAdd} style={{ "width": "46px", "height": "46px" }}>
                <img alt="Adicionar" src={add} style={{ "height": "20px", "width": "20px" }} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContentTable>
                <Table columns={React.useMemo(
                  () => [
                    { Header: 'ID', accessor: 'personId', minWidth: 81, Filter: DefaultColumnFilter },
                    {
                      Header: 'Data de registro',
                      accessor: 'register',
                      Filter: DefaultColumnFilter,
                      filter: 'hardText',
                      myCustomRender: (value) => formatBr(value, false, true),
                      customCompare: (originalObject, value) => formatBr(originalObject.register, false, true).includes(value)
                    },
                    { Header: 'Nome', accessor: 'name', Filter: DefaultColumnFilter },
                    { Header: 'Documento', accessor: 'document', Filter: DefaultColumnFilter, myCustomRender: (value) => documentFormater(value) },
                    {
                      Header: 'Nascimento',
                      accessor: 'birthDate',
                      Filter: DefaultColumnFilter,
                      filter: 'hardText',
                      myCustomRender: (value) => formatBr(value, false, true),
                      customCompare: (originalObject, value) => formatBr(originalObject.birthDate, false, true).includes(value)
                    },
                    {
                      Header: 'Telefone',
                      accessor: 'phone',
                      myCustomRender: (value, original) => {
                        const list = [];
                        for (let i = 0; i < original.contacts.length; i++)
                          if (original.contacts[i].type === 1)
                            list.push(original.contacts[i].value)
                        return list.map((element) => <>{element}</>)
                      },
                      Filter: DefaultColumnFilter,
                      filter: 'hardText',
                      customCompare: (originalObject, value) => {
                        for (let i = 0; i < originalObject.contacts.length; i++)
                          if (originalObject.contacts[i].type === 1 && originalObject.contacts[i].value.includes(value))
                            return true;
                        return false;
                      }
                    },
                    {
                      Header: 'E-mail',
                      accessor: 'email',
                      myCustomRender: (value, original) => {
                        const list = [];
                        for (let i = 0; i < original.contacts.length; i++)
                          if (original.contacts[i].type === 2)
                            list.push(original.contacts[i].value)
                        return list.map((element) => <>{element}</>)
                      },
                      Filter: DefaultColumnFilter,
                      filter: 'hardText',
                      customCompare: (originalObject, value) => {
                        for (let i = 0; i < originalObject.contacts.length; i++)
                          if (originalObject.contacts[i].type === 2 && originalObject.contacts[i].value.includes(value))
                            return true;
                        return false;
                      }
                    },
                  ],
                  []
                )} data={people} />
              </ContentTable>
            </Col>
          </Row>
        </ContentTable>
      </>
    }
  </>;

export default ListPersonComponent;