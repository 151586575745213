import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ThemeProvider } from "styled-components";
import { observer, Provider } from "mobx-react";

import Routes from "./routes";
import userStore from "./stores/UserStore";
import { bbankThemes, bsvThemes, coopermobilThemes, faciegThemes, genialThemes, goBoxThemes, pagueakiThemes, saudesaThemes, triacomThemes } from "./ui/theme/Theme";

import { GlobalStyles } from "./ui/theme/Global";
import AlertComponent from "./ui/atoms/AlertComponent";
import uIStore from "./stores/UIStore";

@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    try {
      const url = window.location.href.toLowerCase();
      if (url.includes("saudesa")) 
        uIStore.themes = saudesaThemes;
      else if (url.includes("bsv"))
        uIStore.themes = bsvThemes;
      else if (url.includes("bbank"))
        uIStore.themes = bbankThemes;
      else if (url.includes("genial"))
        uIStore.themes = genialThemes;
      else if (url.includes("coopermobil"))
        uIStore.themes = coopermobilThemes;
      else if (url.includes("facieg"))
        uIStore.themes = faciegThemes;
      else if (url.includes("pagueaki"))
        uIStore.themes = pagueakiThemes;
      else if (url.includes("gobox"))
        uIStore.themes = goBoxThemes;
      else
        uIStore.themes = triacomThemes;
        // uIStore.themes = bsvThemes;
    } catch (e) { }
  }

  render() {
    return (
      <Provider UIStore={uIStore} UserStore={userStore}>
        <ThemeProvider theme={uIStore.isDarkTheme ? uIStore.themes.darkTheme : uIStore.themes.lightTheme}>
          <GlobalStyles />
          <Routes />
          {/* <div
            style={{
              position: "fixed",
              zIndex: 999999,
              padding: "20px",
              background: "#6856F6",
              marginTop: "-50vh"
            }}>
            <button
              type="button"
              onClick={() => { uIStore.darkTheme = false }} >
              gray
            </button>
            <button
              type="button"
              onClick={() => { uIStore.darkTheme = true }} >
              darkTheme
            </button>
          </div> */}
        </ThemeProvider>
        <AlertComponent />
      </Provider>
    );
  }
}

export default App;
