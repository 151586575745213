/* eslint-disable no-undef */
import { observable, action } from "mobx";
import jwtDecode from "jwt-decode";
import authService from "../services/AuthService";
import auth from "../services/Auth";
import { CURRENT_MARKDOWN, MENUS_KEY } from "../utils/Constants";
import userService from "../services/UserService";
import processService from "../services/ProcessService";
import AlertComponent from "../ui/atoms/AlertComponent";

export class UserStore {
  @observable id = 0;

  @observable document = "";

  @observable name = "";

  @observable menus = {};

  @observable menuscategory = {};

  constructor() {
    (async () => {
      try {
        this.menus = JSON.parse(localStorage.getItem(MENUS_KEY));
      } catch (e) {
        this.menus = {};
      }

      try {
        this.getName();
        this.getId();
      } catch (e) { }
    })();
  }

  getId = () => {
    if (this.id === 0) {
      const { id } = auth.getTokenData();
      this.id = id;
    }
    return this.id;
  };

  getName = () => {
    if (this.name === "") {
      const { name } = auth.getTokenData();
      this.name = name;
    }
    return this.name;
  };

  getToken = () => auth.getTokenData();



  @action.bound
  login = async (document, password) => {
    const data = await authService.login(document, password);
    if (data && data.access_token) {
      const { id } = jwtDecode(data.access_token);
      this.menuscategory = await authService.getMenuscategory(id, data.access_token);
      const singleMenus = await authService.getMenus(id, data.access_token);

      if (
        this.menuscategory == null ||
        this.menuscategory === undefined ||
        singleMenus == null ||
        singleMenus === undefined
      ) {
        AlertComponent.error("Falha ao realizar login, falha ao localizar os acessos", "danger")
        this.logout();
        return null;
      }

      this.setHome(singleMenus)
      this.mapMenu(this.menuscategory)

    //   singleMenus.verticalMenus.push({
    //     "menuId":8001,
    //     "description":"Produtos",
    //     "title":"Produtos",
    //     "content":"",
    //     "type":3,
    //     "order":0,
    //     "parameter":"",
    //     "layout":1,
    //     "key":"marketplaceProduct",
    //     "groupId":4
    // })

      this.deepMapMenu({
        singleMenus,
        fieldMenuName: "horizontalMenus"
      });
      this.deepMapMenu({
        singleMenus,
        fieldMenuName: "verticalMenus"
      });



      auth.login(data.access_token, this.menus);
      this.getName();

      this.document = document;
    }
    return data;
  };

  setHome = ({ home, horizontalMenus, verticalMenus }) => {
    localStorage.setItem("currentMenuSelected", `sideMenu${home.menuId}`)
    localStorage.setItem(CURRENT_MARKDOWN, home.content);
  }

  deepMapMenu = (data) => {
    const { singleMenus, fieldMenuName } = data;
    if (singleMenus != null && singleMenus !== undefined) {
      if (singleMenus[fieldMenuName] != null && singleMenus[fieldMenuName] !== undefined)
        for (let i = 0; i < singleMenus[fieldMenuName].length; i++) {
          const el1 = singleMenus[fieldMenuName][i];
          let hasNotHemnu = true;
          for (let j = 0; j < this.menus[fieldMenuName].length; j++) {
            const el2 = this.menus[fieldMenuName][j];
            if (el2.menus !== undefined && el2.menus[fieldMenuName].length > 0) {
              for (let k = 0; k < el2.menus[fieldMenuName].length; k++) {
                const el3 = el2.menus[fieldMenuName][k];
                if (el1.menuId === el3.menuId)
                  hasNotHemnu = false;
              }
            }
            else if (el1.menuId === el2.menuId)
              hasNotHemnu = false;
          }
          if (hasNotHemnu) {
            this.menus[fieldMenuName].push(el1);
          }
        }
    }
  }

  mapMenu = async (myMenu) => {
    this.menus = {
      horizontalMenus: [],
      verticalMenus: []
    };
    for (let i = 0; i < myMenu.length; i++) {
      const element = myMenu[i];
      element.title = element.description;
      if (element.menus != null && element.menus !== undefined) {
        if (element.menus.verticalMenus != null && element.menus.verticalMenus !== undefined) {
          this.menus.verticalMenus.push(element)
        }

        if (element.menus.horizontalMenus != null && element.menus.horizontalMenus !== undefined) {
          this.menus.horizontalMenus.push(element)
        }
      }
    }
  }

  @action.bound
  getPlans = async () => {
    const data = await userService.getPlans();
    return data;
  };

  @action.bound
  getSimulation = async (data) => {
    const response = await userService.getSimulation(data);
    return response;
  };

  @action.bound
  getSolicitations = async () => {
    const response = await processService.getSolicitations();
    return response;
  };

  @action.bound
  getSolicitationById = async (solicitationId) => {
    const response = await processService.getSolicitationbyId(solicitationId);
    return response;
  };

  @action.bound
  updateStatusSolicitation = async (solicitationUpdate) => {
    const response = await processService.updateStatusSolicitation(solicitationUpdate);
    return response;
  };

  @action.bound
  isLogged = () => auth.isAuthenticated();

  @action.bound
  logout = () => {
    this.document = "";
    this.name = "";
    auth.logout();
  };
}

const userStore = new UserStore();
export default userStore;
