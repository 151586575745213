/* eslint-disable no-undef */
import jwtDecode from "jwt-decode";
import { TOKEN_KEY, TOKEN_EXPIRES_KEY, MENUS_KEY, MENUS_CATEGORY_KEY } from "../utils/Constants";

export class Auth {
  isAuthenticated = () => {
    try {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token == null) return false;
      const { exp } = jwtDecode(token);
      return exp > new Date().getTime() / 1000;
    } catch (e) {
      return false;
    }
  };

  getToken = () => localStorage.getItem(TOKEN_KEY);

  login = (token, menus, menuscategory) => {
    const { exp } = jwtDecode(token);
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(TOKEN_EXPIRES_KEY, exp);
    if (menus != null && menus !== undefined)
      localStorage.setItem(MENUS_KEY, JSON.stringify(menus));
    if (menuscategory != null && menuscategory !== undefined)
      localStorage.setItem(MENUS_CATEGORY_KEY, JSON.stringify(menuscategory));
  };

  getTokenData = () => jwtDecode(localStorage.getItem(TOKEN_KEY));

  logout = () => {
    localStorage.clear();
  };
}

const auth = new Auth();
export default auth;
