import axios from "axios";
import { URL_ACCOUNT_BASE_PATH } from "../utils/Constants";
import auth from "./Auth";

export class UploadService {
    uploadFiles = async (files) => {
        const url = `${URL_ACCOUNT_BASE_PATH}/recurrence/maintainsolicitation/file`;
        try {

            const formData = new FormData();

            for (let i = 0; i < files.length; i++) {
                const element = files[i];
                formData.append(``, element);
            }
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${auth.getToken()}`
                }
            }

            const myApi = axios.create({
                baseURL: process.env.REACT_APP_NOT_SECRET_CODE,
            });

            const { data } = await myApi.post(url, formData, config);
            return data;
        } catch (err) {
            return null;
        }
    };

}

const uploadService = new UploadService();
export default uploadService;
