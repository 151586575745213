import React, { Component } from 'react';
import { MarkerPlaceGetAllMovements, MarkerPlaceGetManintainMovement } from '../../../../../services/MarketplaceService';
import AlertComponent from '../../../../atoms/AlertComponent';
import MovementList from './MovementList';
import MovementMaintain from './MovementMaintain';

class MovementContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            movements: [],
            movementSelected: {},
            isMaintain: false,
            isLoadingSave: false,
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { productId } = this.props;
        const list = await MarkerPlaceGetAllMovements(productId);
        this.setState({
            movements: list,
            isMaintain: false,
            isLoadingSave: false,
        });
    }

    render() {
        const { movementSelected, movements, isMaintain, isLoadingSave } = this.state;
        const { productId } = this.props;
        return <>
            <MovementList movements={movements} onMaintain={(index) => {
                this.setState({
                    movementSelected: index != null && index !== undefined ? movements[index] : {},
                    isMaintain: true
                })
            }} />

            <MovementMaintain
                movementSelected={movementSelected}
                productId={productId}
                isOpen={isMaintain}
                isLoadingSave={isLoadingSave}
                closeModal={() => {
                    this.setState({ isMaintain: false })
                }}
                register={async (obj) => {
                    this.setState({ isLoadingSave: true })
                    const res = await MarkerPlaceGetManintainMovement(obj, (error) => {
                        AlertComponent.error(error);
                        this.setState({ isLoadingSave: false })
                    });
                    if (res != null && res !== undefined) {
                        const { error } = res;
                        if (error != null && error !== undefined && error.trim() !== "")
                            AlertComponent.error(error)
                        if (error != null && error !== undefined && error.trim() !== "")
                            AlertComponent.error("Falha ao salvar o produto")
                        else
                            AlertComponent.sucess("Produto salvo")
                        this.load();
                    }
                }}
            />

        </>

    }
}

export default MovementContainer;