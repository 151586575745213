import React, { Component } from 'react';
import { MarkerPlaceGetAllProducts, MarkerPlaceGetManintainProduct } from '../../../../services/MarketplaceService';
import { showChat } from '../../../../utils/Util';
import AlertComponent from '../../../atoms/AlertComponent';
import ProductList from './ProductList';
import ProductMaintain from './ProductMaintain';

class ProductContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
            productSelected: {},
            isMaintain: false,
            isLoadingSave: false,
        }
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const list = await MarkerPlaceGetAllProducts();
        this.setState({
            products: list,
            isMaintain: false,
            isLoadingSave: false,
        });
    }

    render() {
        const { productSelected, products, isMaintain, isLoadingSave } = this.state;

        return <>
            <ProductList products={products} onMaintain={(index) => {
                this.setState({
                    productSelected: index != null && index !== undefined ? products[index] : {},
                    isMaintain: true
                })
            }} />
            <ProductMaintain
                productSelected={productSelected}
                isOpen={isMaintain}
                isLoadingSave={isLoadingSave}
                closeModal={() => {
                    this.setState({ isMaintain: false })
                }}
                register={async (obj) => {
                    this.setState({ isLoadingSave: true })
                    const res = await MarkerPlaceGetManintainProduct(obj, (err) => {
                        AlertComponent.error(err)
                        this.setState({ isLoadingSave: false })
                    });
                    if (res != null && res !== undefined) {
                        const { error, productId } = res;
                        if (error != null && error !== undefined && error.trim() !== "")
                            AlertComponent.error(error)
                        if (error != null && error !== undefined && error.trim() !== "")
                            AlertComponent.error("Falha ao salvar o produto")
                        else
                            AlertComponent.sucess("Produto salvo")
                        this.load();
                    }
                }}
            />


            {showChat()}
        </>

    }
}

export default ProductContainer;