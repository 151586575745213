import styled, { createGlobalStyle } from "styled-components";


export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html, body {
    overflow: hidden;
    height: 100vh;
  }

  body {
    align-items: center;
    background: ${({ theme }) => theme.backgroundDark};
    color: ${({ theme }) => theme.contrastBackgroundDark};
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

  ::-webkit-scrollbar-track
  {
      box-shadow: transparent;
      background-color: transparent;
  }

  ::-webkit-scrollbar
  {
    height: 5px;
    width: 5px;
    border-radius: 15%;
    background-color: #2E353B;
  }

  ::-webkit-scrollbar-thumb
  {
      background-color: #F5F5F5;
  }

  .form-control{
    margin-top: 5px;
    margin-bottom: 20px;
    border-radius: 5px;
    display: inline-block;
    outline: none;
    background-color: ${({ theme }) => theme.contrastBackgroundDarkMediun}cf;
    color: ${({ theme }) => theme.fieldColor};
    border: 1px solid ${({ theme }) => theme.fieldColor}cf;
    ::placeholder {
      color: ${({ theme }) => theme.backgroundDarkMediun};
      opacity: 0.6; 
    }
    &:focus {
      border: 1px solid ${({ theme }) => theme.fieldColor};
      background-color: ${({ theme }) => theme.contrastBackgroundDarkMediun};
      color: ${({ theme }) => theme.fieldColor};
    }
  }

  .list-group-item {
    background-color: ${({ theme }) => theme.contrastBackgroundDarkMediun}cf;
    color: ${({ theme }) => theme.backgroundDarkMediun};

    input{
      background-color: ${({ theme }) => theme.contrastBackgroundDarkMediun}cf;
      color: ${({ theme }) => theme.backgroundDarkMediun};
    }
  }

  .dark-modal .modal-content{
    background-color: #20262E !important;
  }

  .modal-header button span{
    color: #fff;
  }

  .card {
    color: #fff;
    background-color: ${({ theme }) => theme.backgroundDarkMediun};
    border-radius: 10px;
    img {
      width: 100%;
    }
  }

  .btn-primary {
    background-color: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.textSecondary} !important;
    border-color: ${({ theme }) => theme.secondary};

    &:hover, &:active, &:focus, &:checked{
      background-color: ${({ theme }) => theme.secondary};
      border-color: ${({ theme }) => theme.secondary};
    }

    &:disabled{
      background-color: ${({ theme }) => theme.secondary}7a;
      border-color: ${({ theme }) => theme.secondary}7a;
    }
  }

  .modal-header .close{
    background: transparent;
    border: none;
  }

  .alice-carousel__stage-item{
    width: 100% !important;
  }


  .selectLabel{
    position: relative;
    &:after{
    
  }
  }
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 2fr;
  min-height: calc(100vh - 62px);

  @media only screen and (min-width: 992px) {
    grid-template-columns: ${({ hasSimulation }) =>
    hasSimulation ? `1fr 2fr` : `auto`};
    grid-template-rows: auto;
  }
`;

export const Content = styled.div`
  justify-self: center;
  /* align-self: center; */

  width: 100%;
  @media only screen and (min-width: 992px) {
    width: ${({ hasSimulation }) => (hasSimulation ? `60%` : `85%`)};
  }
`;

export const ContentTable = styled.div`
  height: 100%;
`;

export const MyTable = styled.table`
    width: 100%;
    border-spacing: 0;
    border: ${({ isFullBorder, theme }) => isFullBorder ? `1px solid ${theme.backgroundDark}` : `none`};

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${({ theme }) => theme.backgroundDark};
      border-right: ${({ isFullBorder, theme }) => isFullBorder ? `1px solid ${theme.backgroundDark}` : `none`};
      font-size: 0.8rem;
      :last-child {
        border-right: 0;
      }
    }

    tbody tr:nth-of-type(odd) {
      background-color: ${({ theme }) => theme.backgroundDarkMediun}a1;
      color:  ${({ theme }) => theme.contrastBackgroundDarkMediun};
    }
    
    tbody tr {
      &:hover{
        background-color: ${({ theme }) => theme.backgroundDarkMediun} !important;
        color:  ${({ theme }) => theme.contrastBackgroundDarkMediun};
      }
    }

    tbody .error{
      background-color: RGBA(220,40,51,1) !important;
      color:   #fff !important;

      &:hover{
        background-color: RGBA(235,40,51,1) !important;
      }
    }

    tbody .noHover{
      &:hover{
        background-color: auto;
        color: auto;
      }
      
    }

`;

export const BackButton = styled.button`
  background-color:  ${({ theme }) => theme.backgroundDarkMediun}a1;
  padding: 8px;
  border-radius: 5px;
  border: none;
  color: ${({ theme }) => theme.contrastBackgroundDarkMediun};
  margin-bottom: 20px;
  transition-duration: 500ms;
  img {
    width: 15px;
    margin-right: 5px;
  }
  &:hover{
    background-color:  ${({ theme }) => theme.backgroundDarkMediun};
  }
`